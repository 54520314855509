/*

[Table of contents] 

1. SITE STRUCTURE and TYPOGRAPHY
- 1.1 Typography
- 1.2 Buttons
- 1.3 Structure

2. CONTENT
- 2.1 Home
- 2.2 About
- 2.3 Listing
- 2.4 Detail page
- 2.5 Login/register
- 2.6 Checkout
- 2.7 Account
- 2.8 Map styles
- 2.9 Contacts
- 2.10 404 page
- 2.11 Media Gallery
- 2.12 Faq
- 2.13 Bookings
- 2.14 Autocomplete Address
- 2.15 OpenStreet Map

3. COMMON
- 3.1 Misc
- 3.2 Accordion
- 3.3 List
- 3.4 Spacing
- 3.5 Block reveal
- 3.6 Cookie bar
- 3.7 Sing In Modal
- 3.8 Datepicker

/*============================================================================================*/
/* 1.  SITE STRUCTURE and TYPOGRAPHY */
/*============================================================================================*/

@import 'variables';
@import 'mixin';

/* MAIN NAVIGATION */
/* All styles for screen size over 992px*/
@media only screen and (min-width: 992px) {
	nav#menu.main-menu {
		display: block !important;
	}
	#mm-menu.main-menu {
		display: none !important;
	}
	header .btn_mobile {
		display: none !important;
	}
	/* Menu */
	.main-menu {
		position: relative;
		z-index: 9;
		width: auto;
		top: 8px;
		right: 15px;
		float: right;
		@include transition-default;
		color: #fff;
		ul,
		ul li {
			position: relative;
			display: inline-block;
			margin: 0;
			padding: 0;
			a {
				position: relative;
				margin: 0;
				padding: 0;
				display: block;
				padding: 5px 10px;
				white-space: nowrap;
			}
		}
	}
	/*First level styles */
	.main-menu>ul>li span {
		>a {
			color: #fff;
			padding: 0 8px 15px 8px;
			@include fontSize(14px);
			font-weight: 500;
		}
		&:hover>a {
			opacity: 0.7;
		}
	}
	header.sticky .main-menu>ul>li span,  header.header_in .main-menu>ul>li span{
		>a {
			color: #444;
		}
		&:hover>a {
			opacity: 1;
			color: $color-1;
		}
	}
	
	/* Submenu*/
	.main-menu ul ul {
		position: absolute;
		z-index: 1;
		visibility: hidden;
		left: 3px;
		top: 93%;
		margin: 0;
		display: block;
		padding: 0;
		background: #fff;
		min-width: 200px;
		@include box-shadow (0px 6px 12px 0px rgba(0, 0, 0, 0.175));
		transform: scale(0.4);
		transform-origin: 10% top;
		transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1);
		opacity: 0;
		@include transition-default (all, 0.2s, ease);
		@include border-radius(3px);
		&:before {
			bottom: 100%;
			left: 15%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-bottom-color: #fff;
			border-width: 7px;
			margin-left: -7px;
		}
		li {
			display: block;
			height: auto;
			padding: 0;
			a {
				@include fontSize(13px);
				color: $body-text-color;
				border-bottom: 1px solid #ededed;
				display: block;
				padding: 15px 10px;
				line-height: $line-height-none;
			}
		}
	}
	.main-menu ul li:hover>ul {
		padding: 0;
		visibility: visible;
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transition-delay: 0.2s;
		transition-delay: 0.2s;
		-webkit-transition-duration: 0s, 0.2s, 0.2s;
		transition-duration: 0s, 0.2s, 0.2s
	}
	.main-menu ul ul li {
		&:first-child a {
			&:hover {
				@include border-radius(3px 3px 0 0);
			}
		}
		&:last-child a {
			border-bottom: none;
			&:hover {
				@include border-radius(0 0 3px 3px);
			}
		}
		&:hover>a {
			background-color: #f9f9f9;
			color: $color-1;
			padding-left: 15px;
		}
	}
	
}

/* Submenu 2nd level right */
.main-menu ul ul {
	&.second_level_right {
		left: auto;
		right: 3px;
		&:before {
			right: 15%;
			left: auto;
		}
	}
}

/* Submenu 3rd level */
.main-menu ul ul li span a {
	font-weight: normal!important;
	&:hover {
		background-color: #f9f9f9;
		color: $color-1;
		padding-left: 18px;
		opacity: 1;
	}
	&:after {
		font-family: 'ElegantIcons';
		content: "\35";
		float: right;
		@include fontSize(16px);
		margin-top: -2px;
	}
}
.main-menu ul ul ul {
	position: absolute;
	border-top: 0;
	z-index: 1;
	height: auto;
	left: 100%;
	top: 0;
	margin: 0;
	padding: 0;
	background: #fff;
	min-width: 190px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	&.third_level_left {
		right: 100%;
		left: auto;
	}
	li a {
		border-bottom: 1px solid #ededed!important;
	}
}

.main-menu ul ul ul li:last-child a{
	border-bottom: none!important;
}

/* Arrows top 3rd level*/
.main-menu ul ul ul:before {
	border-width: 0;
	margin-left: 0;
}

/* All styles for screen size under 991px */
@media only screen and (max-width: 991px) {
	
	nav#menu {
		display: none !important;
	}
	.mm-menu {
		background: #fff;
	}
	.main-menu {
		top: 0;
		right: 0;
		float: none;
	}
	ul.mm-listview {
		line-height: 25px;
		li {
			a {
				color: #ccc;
				display: block;
			}
		}
	}
	
}

#menu.fake_menu {
	display: none!important;
	visibility: hidden!important;
}

/*-------- 1.1 Typography --------*/
/* rem reference
10px = 0.625rem
12px = 0.75rem
14px = 0.875rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem
*/

html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body {
    background: $body-color;
    font-size: $font-size-base;
    line-height: $line-height-base;
	font-family: $font-main;
    color: $body-text-color;
}
h1,
h2,
h3,
h4,
h5,
h6 {
   color: $heading-color;
}
p {
	margin-bottom: 30px;
}
strong {
	font-weight: 600;
}
label {
	font-weight: 500;
	margin-bottom: 3px;
}
hr {
    margin: 30px 0 30px 0;
	background-color: $border-color-5;
	opacity: 1;
}
ul,ol {
	list-style: none;
	margin: 0 0 25px 0;
	padding: 0;
}
.main_title {
	text-align: center;
	margin-bottom: 45px;
	position: relative;
}
h1.main_title_in {
	@include fontSize(26px);
	margin: 0 0 25px 0;
	@include extra-small-mobile {@include fontSize(24px);}
}
.main_title span {
	content: "";
	display: block;
    width: 60px;
    height: 3px;
	margin: auto;
	margin-bottom: 20px;
    opacity: 1;
    background-color: #999;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
}
.main_title.animated span{
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}
.main_title_2 {
	span {
		width:120px; 
		height:2px; 
		background-color:#e1e1e1; 
		display:block; 
		margin:auto;
		em {
			width:60px; 
			height:2px; 
			background-color:$color-1; 
			display:block; 
			margin:auto;
		}
	}
	text-align: center;
	margin-bottom: 45px;
	position: relative;
	@include mobile {margin-bottom: 10px;}
	h2 {
		margin:25px 0 0 0;
		@include mobile {@include fontSize(26px);}
	}
	p {
		@include fontSize(24px);
		font-weight: 300;
		line-height: 1.4;
		@include mobile {@include fontSize(18px);}
	}
}

.main_title_3 {
	position: relative;
	border-left: 2px solid #e1e1e1;
	padding-left: 15px;
	@include mobile {border-left: none; padding-left:0;}
	span {
		width:2px; 
		height:30px; 
		background-color:$color-1;
		position: absolute;
		left: -2px;
		top:0;
		display:block; 
		@include mobile {display: none;}
	}
	h2,h3 {
		@include fontSize(26px);
		margin:0;
		display: inline-block;
		@include mobile {@include fontSize(21px);}
	}
	p {
		@include fontSize(18px);
		font-weight: 300;
		line-height: 1.4;
		margin-bottom: 20px;
		@include mobile {@include fontSize(14px);}
	}
	a {
		display: inline-block;
		right: 0;
		bottom: 0;
		position: absolute;
		@include mobile {top:-10px;}
		&:after {
			font-family: 'ElegantIcons';
			content: "\24";
			@include fontSize(21px);
			@include transition-default;
			position: relative;
			top:5px;
			margin-left: 4px;
		}
	}
}

/*General links color*/
a {	
	color: $color-1;
	text-decoration: none;
	@include transition-default;
	outline:none;
	&:hover, &:focus {color: #111;text-decoration: none; outline:none;}
}
/*-------- 1.2 Buttons --------*/
a.btn_1,
.btn_1 {
    border: none;
    color: #fff;
    background: $color-1;
    outline: none;
	cursor: pointer;
    display: inline-block;
    text-decoration: none;
    padding: 15px 30px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    line-height: $line-height-none;
	@include transition-default;
	@include border-radius(3px);
	&:hover {
			background-color: $color-5;
			color: #222!important;
		}
	&.full-width{
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 5px;
		&.purchase{
			background-color: $color-1;
			&:hover {
				background-color: $color-3;
				color: #fff!important;
			}
		}
		&.wishlist{
			border-color: #555;
			color: #555;
			&:hover {
				color: #fff!important;
				background-color: #555;
				border-color: #555;
			}
		}
	}
	&.medium {
		@include fontSize(16px);
		padding: 18px 40px;
	}
	&.rounded {
		@include border-radius(25px!important);
	}
	&.outline {
		border: 2px solid $color-1;
		color: $color-1;
		padding: 11px 40px;
		background: none;
		&:hover {background: $color-2; color:#fff; border-color:$color-2;}
		&:focus {outline: none;}
	}
}

a.btn_add,
.btn_add {
	border: none;
	color: #fff;
	background: $color-1;
	outline: none;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	color: #fff!important;
	font-weight: 600;
	@include transition-default;
	@include border-radius(3px);
	line-height: 1!important;
	padding: 10px 15px!important;
	position: relative;
	top:2px;
	@include tablet {display: none;}
	&:hover {
		background-color: $color-5;
		color: #222!important;
		opacity: 1!important;
	}
}

.closebt {
	@include transition-default;
	cursor:pointer;
	position: absolute;
	@include fontSize(24px);
	top:10px;
	right: 15px;
	color: #fff;
	opacity: 0.6;
	width: 30px;
	height: 30px;
	&:hover {@include rotate(90deg);opacity: 1;}
}

.btn_home_align {
	text-align: right;
	@include mobile {text-align: center; margin-bottom: 15px;}
}

a.btn_map {
	@include border-radius(3px);
    padding: 8px 12px 8px 10px;
	line-height: 1;
    margin-bottom: 5px;
	display: inline-block;
	border: 1px solid rgba(0,0,0,0.08);
	font-weight: 600;
	@include fontSize(12px);
	color: rgba(0,0,0,.5);
	&:hover{
		color: $color-1;
	}
	&:before{
		font-family: 'ElegantIcons';
		content: "\e081";
		display: inline-block;
		margin-right: 5px;
	}
}
a.btn_filt, a.btn_filt_map {
	@include border-radius(3px);
    padding: 8px 12px 8px 10px;
	line-height: 1;
    margin-bottom: 5px;
	display: inline-block;
	border: 1px solid rgba(0,0,0,0.08);
	font-weight: 600;
	@include fontSize(12px);
	color: rgba(0,0,0,.5);
		position: relative;
	@include mobile {
		text-indent: -999px;
		display: block;
		width: 32px;
		height: 32px;
		padding: 0;
	}
	&:hover{
		color: $color-1;
	}
	&:before{
		font-family: 'ElegantIcons';
		content: "\67";
		display: inline-block;
		margin-right: 5px;
		@include fontSize(13px);
		@include mobile {
			text-indent: 1px;
			position: absolute;
			left: 3px;
			top:4px;
			@include fontSize(21px);
			font-weight: normal;
		}
	}
}
a.btn_map_in,
.btn_map_in {
    border: none;
    color: #fff;
    background: $color-1;
    outline: none;
	cursor: pointer;
    display: inline-block;
    text-decoration: none;
    padding: 15px 30px;
    color: #fff;
    font-weight: 600;
    text-align: center;
	width: 100%;
	margin-bottom: 20px;
	display: inline-block;
    line-height: $line-height-none;
	@include transition-default;
	@include border-radius(3px); 
	&:hover {background: $color-2; color:#fff;}
}
/*-------- 1.3 Structure --------*/
/* Header */
header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	padding: 20px 30px;
	z-index: 99999999;
	border-bottom: 1px solid rgba(255, 255, 255, 0);
	&.header {
		@include mobile {
			padding: 15px 15px 5px 15px;
		}
		.logo_sticky {
			display: none;
		}
		&.sticky {
			@include transition-default;
			border-bottom: 1px solid $border-color-2;
			background-color: #fff;
			padding: 15px 20px;
			@include tablet {
				padding: 15px 15px 5px 15px;
			}
			.logo_normal {
				display: none;
			}
			.logo_sticky {
				display: inline-block;
			}
		}
	}
	&.header_in {
		padding: 10px 0;
		background-color: #fff;
		position: relative;
		border-bottom: 1px solid $border-color-2;
		&.is_sticky {
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
		}
		&.map_view {
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
		}
		ul#top_menu li a {
			color: #444;
		}
		@include tablet {
			padding: 10px 0 5px 0;
			ul#top_menu {
				position: absolute;
				right: 15px;
				float: none;
			}
			#logo a {
				z-index: 9; 
				position: relative;
			}
		}
	}
}

.sub_header_in {
	background-color: $color-1;
	padding: 20px 0;
	&.sticky_header {
		margin-top: 58px;
		@include tablet {margin-top: 48px;}
	}
	h1 {
		color: #fff;
		margin: 0;
		@include fontSize(26px);
		@include extra-small-mobile {@include fontSize(18px);}
	}
}

ul#top_menu {
	float: right;
	margin: 0 0 0 10px;
	padding: 0;
	@include fontSize(13px);
	@include tablet {
			margin: -2px 0 0 10px;
		}
	li {
		float: left;
		margin: 0;
		line-height:1;
		margin-right: 15px;
		&:last-child {
			margin-right: 0;
			position: relative;
			top: 0;
		}
		@include tablet {
			margin: 2px 0 0 10px;
		}
		a {
			color: #fff;
			&:hover {
				color: #fff;
				opacity: 0.7;
			}
			&.login,
			&.wishlist_bt_top{
				display: block;
				width: 22px;
				height: 23px;
				position: relative;
				top: 8px;
				@include tablet {
					top: 2px;
				}
				@include transition-default (opacity, 0.5s, ease);
				&:before {
					font-family: 'Glyphter';
					@include fontSize(21px);
					text-indent: 0;
					position: absolute;
					left: 0;
					top: 0;
					font-weight: normal;
					line-height: $line-height-none;
					.sticky & {
						color: #444;
					}
				}
			}
			&.wishlist_bt_top {
				text-indent: -9999px;
				overflow: hidden;
				&:before {
						content:'\0043';
					}
			}
			&.login {
				text-indent: -9999px;
				overflow: hidden;
				&:before {
						content:'\0042';
					}
			}
		}
	}
}

header.sticky .hamburger-inner, header.sticky .hamburger-inner::before, header.sticky .hamburger-inner::after, header.header_in .hamburger-inner, header.header_in .hamburger-inner::before, header.header_in .hamburger-inner::after  {
    background-color: #444;
}

#logo {
	float: left;
	@include tablet {
		float: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		text-align: center;
		img {
			width: auto;
			height: 28px;
			margin: 12px 0 0 0;
			.header_in & {
				margin: 0;
			}
		}
	}
}


/* Main */
main {
    background-color: $body-color;
	position: relative;
	z-index: 1;
	&.pattern {
		background: #f8f8f8 url(../img/pattern_3.svg) no-repeat center center;
		@include background-size(cover); 
		position: relative;
		z-index: 1;
	}
}

/* Footer */
@media (min-width: 576px) {
  .collapse.dont-collapse-sm {
    display: block!important;
    height: auto !important;
    visibility: visible!important;
  }
}
footer {
	border-top:1px solid $border-color-2;
	background-color: #fff;
	    h3 {
    margin: 0 0 15px 0;
    @include fontSize(18px);
    @include extra-small-mobile {
        @include fontSize(13px);
        cursor: pointer;
        border-bottom: 1px solid #ededed;
        padding: 12px 0;
        display: block;
        &:after {
            font-family: 'themify';
            content: "\e61a";
            width: 20px;
            display: block;
            float: right;
        }
        &.opened {
            &:after {
                content: "\e622";
            }
        }
         }
        @include extra-small-mobile {
            @include fontSize(16px);
            margin: 0;
        }
    }
	hr {
		@include extra-small-mobile {
				display: none;
			}
	}
	ul {
		@include extra-small-mobile {
				padding-top: 15px;
			}
		li {
			margin-bottom: 5px;
			a {
				@include transition-default;
				display: inline-block;
				position: relative;
				color: $body-text-color;
				&:hover {
					color: $color-1;
					opacity: 1;
				}
				i {
					margin-right: 10px;
					color: #fff;
				}
			}
		}
		&.links li a {
			&:hover {
				@include transform (translate(5px, 0));
				&:after {
					opacity: 1;
					color: $color-1;
				}
			}
			&:after {
				font-family: 'ElegantIcons';
				content: "\24";
				position: absolute;
				margin-left: 5px;
				top: 1px;
				opacity: 0;
				@include transition-default (all, 0.5s, ease);
			}
		}
		&.contacts li {
			padding-left: 30px;
			position: relative;
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
			i {
				position: absolute;
				top: 0;
				left: 0;
				color: $color-1;
				line-height: 1;
				@include fontSize(18px);
			}
		}
	}
	#newsletter {
		@include extra-small-mobile {
				padding-top: 15px;
			}
	}
}

/* Newsletter footer*/
#newsletter {
	.form-group {
		position: relative;
	}
	.loader {
		position: absolute;
		right: -20px;
		top: 11px;
		color: #444;
		@include fontSize(12px);
	}
	h6 {
		margin:15px 0 15px 0;
	}
	.form-group {
		position: relative;
	}
	input[type='email'] {
		border: 0;
		height: 40px;
		@include border-radius(0);
		padding-left: 15px;
		@include fontSize(14px);
		@include rgba(bg, #000, 0.06);
		&:focus{
			border: 0;
			box-shadow: none;
		}
	}
	input[type='submit'] {
		position: absolute;
		right: 0;
		color: #fff;
		@include fontSize(13px);
		font-weight: 600;
		top: 0;
		border: 0;
		padding: 0 12px;
		height: 40px;
		line-height: 42px;
		cursor: pointer;
		@include border-radius(0 3px 3px 0);
		background-color: $color-1;
		@include transition-default;
		outline: none;
		&:hover {
			background-color: $color-5;
			color: #222;
		}
	}
}

.follow_us {
	@include animation-delay (1.1s);
	h5 {
		@include fontSize(16px);
		margin-top: 35px;
	}
	ul {
		li {
			display: inline-block;
			margin-right: 5px;
			@include fontSize(20px);
			a i {
				color: $body-text-color;
				opacity: 0.6;
				&:hover {
					opacity: 1;
					color: $color-1;
				}
			}
		}
	}
}

ul#footer-selector{
	margin: 0 0 0 0;
	padding: 0;
	list-style: none;
	@include extra-small-mobile {margin-top: 30px;}
	li {
		float: left;
		margin-right: 10px;
		&:last-child{
		@include extra-small-mobile {margin-top: 5px;}
	}
	}
}

/* Addtional links Footer */
ul#additional_links {
    margin: 0;
	padding: 8px 0 0 0;
    color: #555;
    @include fontSize(13px);
	float: right;
	@include tablet {float: none; margin-top: 10px;}
	li {
		display: inline-block;
		margin-right: 15px;
		&:first-child {margin-right: 20px;}
		&:last-child:after {content: "";}
		span {
			color:$body-text-color;
			opacity: 0.8;
		}
		a {
			color:$body-text-color;
			opacity: 0.8;
			@include transition-default;
			&:hover {opacity: 1;}
			
		}
		&:after {content: "|";font-weight: 300;position: relative;left: 10px;}
	}
}

.styled-select {
	width: 100%;
	overflow: hidden;
	height: 30px;
	line-height: 30px;
	border: none;
	@include rgba(bg, #000, 0.05);
	@include border-radius(3px);
	padding-right: 35px;
	position: relative;
	&#lang-selector {
		color: #555;
		&:after {
			font-family: 'ElegantIcons';
			content: "\e0e2";
			color: #555;
			position: absolute;
			right: 10px;
			top:0;
		}
	}
	&#currency-selector {
		&:after {
			font-family: 'ElegantIcons';
			content: "\33";
			color: #555;
			position: absolute;
			right: 10px;
			top:0;
		}
	}
	select {
		background: transparent;
		width: 110%;
		padding-left: 10px;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		height: 30px;
		color: #555;
		@include fontSize(12px);
		margin: 0;
		font-weight: 500;
		-moz-appearance: window;
		-webkit-appearance: none;
		cursor: pointer;
		outline: none;
		&:focus {
			color: #555;
			outline: none;
			box-shadow: none;
		}
		&::-ms-expand {
			display: none;
		}
	}
}

/* Wow on scroll event */
 .wow {
	 visibility: hidden;
}

/*============================================================================================*/
/* 2.  CONTENT */
/*============================================================================================*/

/*-------- 2.1 Home/front page--------*/
/* Home single hero */
.hero_single {
	width: 100%;
	position: relative;
	text-align: center;
	margin: 0;
	color: #fff;
	.wrapper {
		@include rgba(bg, #000, 0.8);
	}
	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		h3 {
			color: #fff;
			@include fontSize(52px);
			margin: 0;
			text-transform: uppercase;
			font-weight: 700;
			@include mobile {
				@include fontSize(21px);
			}
			@include extra-small-mobile {
				@include fontSize(23px);
			}
		}
		p {
			font-weight: 300;
			margin: 10px 0 0 0;
			padding: 0 20%;
			@include fontSize(24px);
			line-height: 1.4;
			strong {
				font-weight: 600;
			}
			@include mobile {
				padding: 0;
				@include fontSize(18px);
			}
		}
	}
	&.short {
		height: 600px;
	}
	&.version_2 {
		height: 620px;
		background: #222 url(../img/home_section_1.jpg) center center no-repeat;
		@include background-size(cover);
		.wrapper {
			@include rgba(bg, #000, 0.6);
			h3 {
				@include fontSize(42px);
				text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
				@include mobile {
					@include fontSize(26px);
					margin-top: 30px;
				}
				@include extra-small-mobile {
					@include fontSize(22px);
				}
			}
			p {
				font-weight: 400;
				margin: 5px 0 20px 0;
				padding: 0;
				@include fontSize(21px);
				strong {
					font-weight: 600;
				}
				text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
				@include mobile {
					padding: 0;
					@include fontSize(14px);
				}
			}
		}
	}
	&.version_3 {
		height: 450px;
		background: #222 url(../img/home_section_1.jpg) center center no-repeat;
		@include background-size(cover);
		.wrapper {
			@include rgba(bg, #000, 0.6);
		}
	}
	&.version_4 {
		height: 620px;
		background: $color-1 url(../img/pattern_1.svg) center bottom repeat-x;
		.wrapper {
			@include background-gradient(rgba(0, 0, 0, 0.6), transparent, 'vertical');
			h3 {
				@include fontSize(42px);
				text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
				@include mobile {
					@include fontSize(26px);
					margin-top: 30px;
				}
				@include extra-small-mobile {
					@include fontSize(22px);
				}
			}
			p {
				font-weight: 400;
				margin: 5px 0 20px 0;
				padding: 0;
				@include fontSize(21px);
				strong {
					font-weight: 600;
				}
				text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
				@include mobile {
					padding: 0;
					@include fontSize(14px);
				}
			}
			input[type='submit'] {
				color: #222;
				background-color: $color-5;
				@include tablet {
					margin: 20px 0 0 0;
					@include border-radius(3px);
				}
				&:hover {
					background-color: $color-3;
					color: #fff;
				}
			}
		}
	}
	&.version_5 {
		height: 640px;
		background: $color-1 url(../img/pattern_2.svg) center center repeat fixed;
		@include mobile {
					height: 550px;
				}
		.wrapper {
			@include background-gradient(rgba(0, 0, 0, 0.6), transparent, 'vertical');
			text-align: left;
			h3 {
				@include fontSize(36px);
				text-shadow: none;
				@include mobile {
					@include fontSize(26px);
					margin-top: 20px;
				}
				@include extra-small-mobile {
					@include fontSize(22px);
				}
			}
			p {
				font-weight: 400;
				margin: 5px 0 20px 0;
				padding: 0;
				@include fontSize(21px);
				strong {
					font-weight: 600;
				}
				text-shadow: none;
				@include mobile {
					padding: 0;
					@include fontSize(14px);
				}
			}
			.custom-search-input-2{
				background: none;
                @include box-shadow (none);
			}
			.form-group{
				margin-bottom: 10px;
				
			}
			input[type='submit'] {
				color: #222;
				margin-top:10px;
				background-color: $color-5;
				@include border-radius(3px);
				@include tablet {
					margin: 20px 0 0 0;
				}
				&:hover {
					background-color: $color-3;
					color: #fff;
				}
			}
		}
	}
}

#custom-search-input {
	padding: 0;
	width: 600px;
	margin: 20px auto 0;
	position: relative;
	@include tablet {
		width: auto;
	}
	.search-query {
		width: 100%;
		height: 50px;
		padding-left: 20px;
		border: 0;
		@include border-radius(3px);
		@include fontSize(16px);
		color: #333;
		&:focus {
			outline: none;
		}
		@include extra-small-mobile {
			padding-left: 15px;
		}
	}
	input[type='submit'] {
		position: absolute;
		@include transition-default;
		right: -1px;
		color: #fff;
		font-weight: 600;
		@include fontSize(14px);
		top: 0;
		border: 0;
		padding: 0 25px;
		height: 50px;
		cursor: pointer;
		outline: none;
		@include border-radius(0 3px 3px 0);
		background-color: $color-1;
		&:hover {
			background-color: $color-5;
			color: #222;
		}
		@include extra-small-mobile {
			text-indent: -999px;
			background: $color-1 url(../img/search.svg) no-repeat center center;
		}
	}
}

.custom-search-input-2 {
	background-color: #fff;
	@include border-radius(5px);
	margin-top: 10px;
	@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.3));
	@include tablet {
		background: none;
		@include box-shadow (none);
	}
	input {
		border: 0;
		height: 50px;
		padding-left: 15px;
		border-right: 1px solid $border-color-3;
		font-weight: 500;
		@include tablet {
			border: none;
		}
		&:focus {
			box-shadow: none;
			border-right: 1px solid $border-color-3;
			@include tablet {
				border-right: none;
			}
		}
	}
	select {
		display: none;
	}
	.nice-select .current {
		font-weight: 500;
		color: #6f787f;
	}
	.form-group {
		margin: 0;
		@include tablet {
			margin-bottom: 5px;
		}
	}
	i {
		@include border-radius(3px);
		@include fontSize(18px);
		position: absolute;
		background-color: #fff;
		line-height: 50px;
		top: 1px;
		right: 1px;
		padding-right: 15px;
		display: block;
		width: 20px;
		box-sizing: content-box;
		height: 48px;
		z-index: 9;
		color: #999;
		@include tablet {
			padding-right: 10px;
		}
	}
	input[type='submit'] {
		@include transition-default;
		color: #fff;
		font-weight: 600;
		@include fontSize(14px);
		border: 0;
		padding: 0 25px;
		height: 50px;
		cursor: pointer;
		outline: none;
		width: 100%;
		@include border-radius(0 3px 3px 0);
		background-color: $color-1;
		margin-right: -1px;
		@include tablet {
			margin: 20px 0 0 0;
			@include border-radius(3px);
		}
		&:hover {
			background-color: $color-5;
			color: #222;
		}
	}
	&.map_view {
		background-color: transparent;
		@include box-shadow (none);
		input{
			border: none;
		}
		input[type='submit'] {
			margin: 20px 0 0 0;
			@include border-radius(3px);
		}
	}
	&.inner {
		margin-top: 0;
		position: relative;
		@include box-shadow (none);
		@include tablet {
			display: none;
		}
		input {
			border: 0;
			height: 40px;
			padding-left: 15px;
			border-right: 1px solid $border-color-3;
			font-weight: 500;
			@include tablet {
				border: none;
			}
			&:focus {
				box-shadow: none;
				border-right: 1px solid $border-color-3;
				@include tablet {
					border-right: none;
				}
			}
		}
		.nice-select {
			height: 40px;
			line-height: 38px;
			&:after { 
				right: 15px; 
		  }
		}
		i {
			height: 38px;
			line-height: 38px;
			padding-right: 10px;
		}
		input[type='submit'] {
			position: absolute;
			@include transition-default;
			width: inherit;
			right: -1px;
			color: #fff;
			font-weight: 600;
			@include fontSize(14px);
			top: 0;
			border: 0;
			height: 40px;
			cursor: pointer;
			outline: none;
			@include border-radius(0 3px 3px 0);
			text-indent: -999px;
			background: $color-5 url(../img/search.svg) no-repeat center center;
			&:hover {
				background-color: $color-3;
			}
			@include extra-small-mobile {
				text-indent: -999px;
				background: $color-1 url(../img/search.svg) no-repeat center center;
			}
		}
		@include tablet {
			margin: 0 0 20px 0;
			@include box-shadow (none);
		}
	}
	&.inner-2 {
		margin: 0 0 20px 0;
		@include box-shadow (none);
		background: none;
		.form-group {
			margin-bottom: 10px;
		}
		input {
			border: 1px solid $border-color-2;
		}
		input[type='submit'] {
			@include border-radius(3px);
			margin-top: 10px;
		}
		i {
			padding-right: 10px;
			line-height: 48px;
			height: 48px;
			top:1px;
		}
		.nice-select{
			border: 1px solid $border-color-2;
		}
	}
}

ul.counter{
	margin: 30px 0 0 0;
	padding: 0;
	text-align: center;
	@include mobile {display: none;}
	li {
		padding:0  10px;
		display: inline-block;
		@include fontSize(18px);
		border-right: 1px solid #fff;
		text-align: right;
		&:last-child{
			border-right: none;
			text-align: left;
		}
	}
}
.main_categories {
	position: relative;
	margin-top: -30px;
	margin-bottom: 25px;
	position: relative;
	z-index: 99;
	ul {
		background-color: #fff;
		@include border-radius(3px);
		@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.10));
		text-align: center;
		padding: 15px 0;
		width: 90%;
		margin: auto;
		@include mobile {width: 100%;}
		li {
			display: inline-block;
			width: 20%;
			float: left;
			border-right: 1px solid $border-color-3;
			&:last-child{
					border-right: none;
				}
			a {
				display: block;
				h3 {
					@include fontSize(16px);
					@include mobile {display:none;}
				}
				i {
					@include fontSize(26px);
					display: inline-block;
				}
			}
		}
	}
}

/* Home video background*/
.header-video {
	position: relative;
	overflow: hidden;
	background: #000;
	height: 600px!important;
	@include tablet {height: 620px!important;}
}
#hero_video {
	position: relative;
	background-size: cover;
	color: #fff;
	width: 100%;
	@include fontSize(16px);
	height: 100%;
	z-index: 99;
	text-align: center;
	display: table;
	.wrapper {
		display: table-cell;
		vertical-align: middle;
		h3 {
			color: #fff;
			@include fontSize(42px);
			text-transform: uppercase;
			font-weight: bold;
			margin-bottom: 0;
			text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
			@include mobile {
				@include fontSize(26px);
				margin-top: 60px;
			}
			@include extra-small-mobile {
				@include fontSize(22px);
			}
		}
		p {
			font-weight: 300;
			@include fontSize(24px);
			line-height: 1.4;
			text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
			strong {font-weight: 600;}
			@include mobile {
				padding: 0;
				@include fontSize(14px);
			}
		}
	}
}
video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0.3;
}
#hero_video iframe {
	height: 100%;
	width: 100%;
}
video {
	width: 100%;
}
.teaser-video {
	width: 100%;
	height: auto;
}
.header-video--media {
	width: 100%;
	height: auto;
}
@media (max-width: 1024px) {
	#hero_video {
		background: #ccc url(../img/home_section_1.jpg);
		@include background-size(cover);
		>div {@include rgba(bg, #000, 0.5);}
	}
	.header-video {
		background: none;
	}
}

a.grid_item {
	display: block;
	margin-bottom: 30px;
	@include border-radius(3px);
	overflow: hidden;
	height: 200px;
	@include tablet {height: 180px;}
	@include mobile {height: 150px;}
	@include extra-small-mobile {height: 180px;}
	.info {
		position: absolute;
		width: 100%;
		z-index: 9;
		display: block;
		padding: 25px 25px 10px 25px;
		color: #fff;
		left: 0;
		bottom: 0;
		@include background-gradient(transparent, #000, 'vertical');
		box-sizing: border-box;
		@include mobile {padding: 15px 15px 0 15px;}
		small {
			display: inline-block;
			margin-bottom: 5px;
			font-weight: 600;
			@include fontSize(11px);
			background-color: $color-1;
			line-height:$line-height-none;
			padding: 3px 5px 2px 5px;
		}
		h3 {
			color: #fff;
			@include fontSize(21px);
		}
		p {
			color: #fff;
			margin-bottom: 0;
			@include fontSize(15px);
		}
	}
	figure {
		position: relative;
		overflow: hidden;
		@include border-radius(3px);
		width: 100%;
		height: 100%;
		@include mobile {height: 150px;}
		@include extra-small-mobile {height: 180px;}
		img {
			@include transition-default;
			position: absolute;
			left: 50%;
			top: 50%;
			@include transform (translate(-50%, -50%) scale(1));
			@include mobile {width: 100%}
		}
	}
	&:hover figure img {
		@include transform (translate(-50%, -50%) scale(1.1));
	}
	&.small {
		height: 170px;
		@include desktop {height: 130px;}
		@include tablet {height: 180px;}
		@include mobile {height: 150px;}
		@include extra-small-mobile {height: 170px;}
		.info {
			padding: 35px 15px 0 15px;
			h3 {
				@include fontSize(16px);
			}
		}
		figure {
		@include mobile {height: 150px!important;}
		@include extra-small-mobile {height: 170px!important;}
		img {
			@include transition-default;
			width: 100%;
		}
	}
	}
}

/*Carousel home page*/
#reccomended {
	margin-top: 40px;
	@include mobile {margin-top: 0;}
	.item {
		margin: 0 15px;
	}
}
#reccomended .owl-item {
	opacity: 0.5;
	transform: scale(0.85);
	@include backface-visibility (hidden);
	-webkit-transform: translateZ(0) scale(0.85, 0.85);
	transition: all 0.3s ease-in-out 0s;
	overflow: hidden;
}

#reccomended .owl-item.active.center {
	opacity: 1;
	@include backface-visibility (hidden);
	-webkit-transform: translateZ(0) scale(1, 1);
	transform: scale(1);
}

#reccomended .owl-item.active.center .item .title h4,
#reccomended .owl-item.active.center .item .views {
	opacity: 1;
}

.owl-theme .owl-dots {
	margin-top: 25px!important;
	outline: none;
	@include mobile {margin-top: 0!important; margin-bottom: 20px;}
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: $color-1 !important;
	outline: none!important;
}

.owl-theme .owl-dots .owl-dot span {
    width: 13px!important;
    height: 13px!important;
}

.app_icons {
	margin-bottom: 10px;
	@include transition-default;
	a img{
		@include transition-default;
		&:hover {
			@include transform (translateY(-5px));
		}
	}
}

a.box_news {
	position: relative;
	display: block;
	padding-left: 230px;
	color: $body-text-color;
	margin-bottom: 30px;
	min-height: 150px;
	@include mobile {
		min-height: inherit;
		padding-left: 0;
	}
	figure {
		width: 200px;
		height: 150px;
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 0;
		@include border-radius(3px);
		@include mobile {
			position: relative;
			width: auto;
		}
		img {
			width: 250px;
			height: auto;
			position: absolute;
			left: 50%;
			top: 50%;
			@include transform (translate(-50%, -50%) scale(1.1));
			@include transition-default;
			@include backface-visibility (hidden);
			@include mobile {
				width: 100%;
				max-width: 100%;
				height: auto;
			}
		}
		figcaption {
			background-color: $color-5;
			text-align: center;
			position: absolute;
			right: 0;
			top: 0;
			display: inline-block;
			color: #fff;
			width: 60px;
			height: 60px;
			padding: 15px;
			line-height: $line-height-none;
			strong {
				display: block;
				@include fontSize(21px);
			}
		}
	}
	h4 {
		@include fontSize(18px);
		margin-bottom: 10px;
		@include mobile {margin-bottom: 5px;}
	}
	&:hover {
		img {
			@include transform (translate(-50%, -50%) scale(1));
		}
		h4 {
			color: $color-1;
			@include transition-default;
		}
	}
	ul {
		margin: 0;
		padding: 10px 0 0 0;
		@include mobile {padding: 0;}
		li {
			display: inline-block;
			font-weight: 600;
			text-transform: uppercase;
			color: #999;
			@include fontSize(12px);
			margin-right: 5px;
			&:last-child {
				margin-right: 0;
				&:after {
					content: '';
					margin: 0;
				}
			}
			&:after {
				content: '-';
				margin: 0 0 0 10px;
			}
		}
	}
}

a.box_cat_home {
	background-color: #fff;
	display: block;
	padding: 25px 15px;
	@include border-radius(3px);
	text-align: center;
	margin-bottom: 25px;
	position: relative;
	@include transition-default (all,
	0.3s);
	@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.10));
	color: #999;
	i {
		position: absolute;
		right: 10px;
		top: 10px;
		@include fontSize(18px);
		color: $color-2;
		opacity: 0;
		@include transition-default (all,0.6s);
	}
	h3 {
		@include fontSize(18px);
		margin-top: 10px;
	}
	&:hover {
		@include box-shadow (0px 0px 20px 0px rgba(0, 0, 0, 0.15));
		@include transform (translateY(-5px));
		i {
			opacity: 1;
		}
	}
	ul {
		margin: 20px -15px -25px -15px;
		padding: 10px;
		border-top: 1px solid $border-color-2;
		text-align: center;
		li {
			display: inline-block;
			strong {
				margin-right: 5px;
				font-weight: 600;
				color: #777;
			}
		}
	}
}

/*-------- 2.2 About --------*/
a.box_feat {
	text-align: center;
	background: #fff;
	padding: 40px 30px 30px 30px;
	@include mobile {padding: 20px 20px 0 20px;}
	display: block;
	@include transition-default (all, 500ms, ease-in-out);
	@include box-shadow (0px 3px 0px 0px #f0f2f4);
	color: $body-text-color;
	margin-bottom: 30px;
	@include border-radius(3px);
	overflow: hidden;
	&:hover {
		@include transform (translateY(-10px));
		@include box-shadow (0px 3px 0px 0px #ebedef);
	}
	i {
		@include fontSize(70px);
		color: $color-3;
		margin-bottom: 10px;
	}
	h3 {
		@include fontSize(18px);
	}
}

#carousel {
	margin-top: 40px;
	@include mobile {
		margin-bottom: 30px;
	}
	.item {
		.title {
			position: absolute;
			bottom: 0;
			left: 0;
			text-align: center;
			width: 100%;
			z-index: 9;
			h4 {
				@include rgba(bg,$color-2,1);
				display: inline-block;
				@include border-radius(3px 3px 0 0);
				color: #fff;
				padding: 10px 15px;
				margin: 0;
				@include fontSize(16px);
				opacity: 0;
				em {
					display: block;
					@include fontSize(13px);
					font-weight: 300;
					margin-top: 3px;
				}
			}
		}
		a {
			position: relative;
			display: block;
		}
	}
}

#carousel .owl-item {
	opacity: 0.5;
	transform: scale(0.85);
	@include backface-visibility (hidden);
	-webkit-transform: translateZ(0) scale(0.85, 0.85);
	transition: all 0.3s ease-in-out 0s;
	overflow: hidden;
}

#carousel .owl-item.active.center {
	opacity: 1;
	@include backface-visibility (hidden);
	-webkit-transform: translateZ(0) scale(1, 1);
	transform: scale(1);
}

#carousel .owl-item.active.center .item .title h4,
#carousel .owl-item.active.center .item .views {
	opacity: 1;
}

/*-------- 2.3 Listing --------*/
#results {
	padding: 20px 0;
	color: #fff;
	background: $color-1;
	position: relative;
	z-index: 999!important;
	@include mobile {
		padding: 12px 0;
	}
	&.is_stuck {
		z-index: 99;
		padding: 10px 0;
	}
	h4 {
		color: #fff;
		margin: 12px 0 0 0;
		padding: 0;
		line-height: 1;
		@include fontSize(16px);
		@include tablet {margin: 5px 0 0 0;}
		@include mobile {margin: 3px 0 0 0;}
	}
	&.map_view {
		padding: 10px 0;
		margin: 0 -15px;
		h4 {
			margin: 3px 0 0 0;
		}
	}
}
#results_map_view {
	padding: 10px 0;
	margin: 0 -15px;
	color: #fff;
	background: $color-1;
	position: relative;
	z-index: 9999999999999999!important;
	h4 {
		color: #fff;
		margin: 3px 0 0 0;
		padding: 0;
		line-height: 1;
		@include fontSize(16px);
		@include tablet {margin: 5px 0 0 0;}
		@include mobile {margin: 3px 0 0 0;}
	}
}
#filters {
	background-color: #fff;
	border-bottom: 1px solid $border-color-2;
	h6 {
		margin-bottom: 15px;
		@include fontSize(16px);
	}
	ul li small {
		font-weight: 600;
		float: right;
		position: relative;
		top: 4px;
	}
}

.map_view#filters {
	margin: 0 -15px;
}

.distance {
	margin-bottom: 15px;
	span {
		font-weight: 600;
		color:$color-1;
	}
}
.rangeslider__handle {
  border: 2px solid $color-1!important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}
.rangeslider__fill {
  background: $color-1!important;
}

.filters_listing {
	padding: 10px 0 5px 0;
	background: #fff;
	border-bottom: 1px solid $border-color-2;
	z-index: 99!important;
	position: relative;
	&.is_stuck {}
	ul {
		margin: 0;
		li {
			margin-right: 10px;
			&:first-child {
				float: left;
			}
			&:nth-child(2) {
				float: left;
				margin-right: 0;
			}
			&:nth-child(3) {
				float: right;
				margin-right: 0;
				@include mobile {
					display: none;
				}
			}
			&:nth-child(4) {
				float: right;
				@include mobile {
					margin-right: 0;
				}
			}
		}
	}
	&.version_2 {
		ul {
			margin: 0;
			li {
				margin-right: 10px;
				&:first-child {
					float: left;
				}
				&:nth-child(2) {
					float: right;
					margin-right: 0;
					@include mobile {
						display: none;
					}
				}
				&:nth-child(3) {
					float: right;
					margin-right: 10px;
					@include mobile {
						display: block;
						margin-right: 0;
					}
				}
			}
		}
	}
	&.version_3 {
		margin: 0 -15px 0 -15px;
		ul {
			margin: 2px 0 0 0;
			li {
				margin-right: 10px;
				&:first-child {
					float: left;
				}
				&:nth-child(2) {
					float: right;
					margin-right: 0;
				}
				
			}
		}
	}
}
.tools_map {
	@include border-radius(3px);
	@include fontSize(23px);
	a {
		color: rgba(0,0,0,.5);
		margin: 0 3px;
		&:hover {
			color: $color-1;
		}
	}
	i {
		font-weight: 500;
		line-height: 1;
	}
}
.score {
	strong {
		background-color: $color-3;
		color: #fff;
		line-height: 1;
		@include border-radius(5px 5px 5px 0);
		padding: 10px;
		display: inline-block;
	}
	span {
		display: inline-block;
		position: relative;
		top:7px;
		margin-right: 8px;
		@include fontSize(12px);
		text-align: right;
		line-height: 1.1;
		font-weight: 500;
		em {
			display: block;
			font-weight: normal;
			@include fontSize(11px);
		}
	}
}

.strip {
	background-color: #fff;
	display: block;
	position: relative;
	margin-bottom: 30px;
	@include border-radius(3px);
	@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.10));
	a.wish_bt {
		position: absolute;
		right: 15px;
		top: 15px;
		z-index: 1;
		@include rgba(bg, #000, 0.6);
		padding: 9px 10px;
		display: inline-block;
		color: #fff;
		line-height: $line-height-none;
		@include border-radius(3px);
		&:after {
			font-family: 'ElegantIcons';
			content: "\e012";
			@include transition-default (all, 0.5s, ease);
		}
		&.liked:after {
			content: "\e078";
			color: $color-5;
		}
		&:hover.liked:after {
			color: $color-5;
		}
		&:hover:after {
			content: "\e078";
			color: #fff;
		}
	}
	figure {
		margin-bottom: 0;
		overflow: hidden;
		position: relative;
		height: 200px;
		@include border-radius(3px 3px 0 0);
		small {
			position: absolute;
			@include rgba(bg, #000, 0.6);
			left: 20px;
			top: 22px;
			text-transform: uppercase;
			color: #fff;
			font-weight: 600;
			@include border-radius(3px);
			padding: 7px 10px 4px 10px;
			line-height: 1;
		}
		.read_more {
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -12px;
			@include transform (translateY(10px));
			text-align: center;
			opacity: 0;
			visibility: hidden;
			width: 100%;
			-webkit-transition: all 0.6s;
			transition: all 0.6s;
			z-index: 2;
			span {
				@include rgba(bg, #fff, 0.8);
				@include border-radius(20px);
				display: inline-block;
				color: #222;
				@include fontSize(12px);
				padding: 5px 10px;
			}
		}
		&:hover .read_more {
			opacity: 1;
			visibility: visible;
			@include transform (translateY(0));
		}
		a {
			img {
				position: absolute;
				left: 50%;
				top: 50%;
				@include transform (translate(-50%, -50%) scale(1));
				@include backface-visibility (hidden);
				width: 100%;
				@include transition-default;
			}
			&:hover img {
				@include transform (translate(-50%, -50%) scale(1.1));
			}
		}
	}
	.wrapper {
		padding: 20px 25px 15px 25px;
		h3 {
			@include fontSize(20px);
			margin: 0;
		}
		small {
			font-weight: 600;
			margin-bottom: 10px;
			display: inline-block;
			@include fontSize(13px);
		}
		p {
			margin-bottom: 15px;
		}
	}
	ul {
		padding: 20px 15px 17px 20px;
		border-top: 1px solid $border-color-2;
		li {
			display: inline-block;
			margin-right: 15px;
			.score {
				margin-top: -10px;
			}
			&:first-child {
				@include fontSize(13px);
				margin-top: -4px;
				i {
					@include fontSize(20px);
					position: relative;
					top: 4px;
				}
			}
			&:last-child {
				margin-right: 0;
				float: right;
			}
		}
	}
	&.list_view {
		min-height: 290px;
		a.wish_bt {
			@include rgba(bg, #000, 0.1);
			color: #555;
			top: 25px;
			right: 25px;
			&.liked:after {
				color: $color-1;
			}
			&:hover.liked:after {
				color: $color-1;
			}
			&:hover:after {
				color: #444;
			}
		}
		.score {
			margin-right: 10px;
		}
		figure {
			margin-bottom: 0;
			overflow: hidden;
			position: relative;
			min-height: 300px;
			@include border-radius(3px 0 0 3px);
			@include tablet {
				min-height: 220px;
				max-height: 220px;
				height: 220px;
				@include border-radius(3px 3px 0 0);
			}
			small {
				right: 30px;
				top: 30px;
				left: inherit;
			}
			a {
				img {
					height: 360px;
					width: auto;
					max-width: none;
					@include transition-default;
					@include tablet {
						max-width: inherit;
						height: 460px;
						width: auto;
					}
					@include mobile {
						height: 350px;
					}
				}
				&:hover img {
					@include transform (translate(-50%, -50%) scale(1.1));
				}
			}
		}
		.wrapper {
			padding: 30px 30px 20px 30px;
			min-height: 235px;
			@include tablet {
				min-height: inherit;
				position: relative;
			}
		}
		ul {
			padding: 20px 15px 20px 30px;
			margin-bottom: 0;
		}
	}
	&.map_view {
		min-height: 120px;
		margin-bottom: 20px;
		a.wish_bt {
			@include rgba(bg, #000, 0.1);
			color: #555;
			top: 15px;
			right: 15px;
			&.liked:after {
				color: $color-1;
			}
			&:hover.liked:after {
				color: $color-1;
			}
			&:hover:after {
				color: #444;
			}
		}
		.score {
			margin-right: 10px;
			@include mobile {
				position: relative;
				top: 3px;
				}
			span {
				@include mobile {
					display: none;
				}
			}
		}
		a.address {
			&:before {
			content: "\e081";
		}
			@include tablet {display: none;}
		}
		figure {
			margin-bottom: 0;
			overflow: hidden;
			position: relative;
			min-height: inherit;
			height: 185px;
			@include border-radius(3px 0 0 3px);
			@include tablet {
				height: 165px;
			}
			small {
				right: 20px;
				top: 20px;
				left: inherit;
			}
			a {
				img {
					height: 210px;
					width: auto;
					max-width: none;
					@include transition-default;
					@include tablet {
						max-width: inherit;
						height: 210px;
						width: auto;
					}
					
				}
				&:hover img {
					@include transform (translate(-50%, -50%) scale(1.1));
				}
			}
		}
		.wrapper {
			padding: 20px 20px 0 20px;
			min-height: 110px;
			small {
				line-height: 1.3;
				margin-top: 5px;
			}
			h3 {
				@include mobile {
					@include fontSize(16px);
				}
			}
			@include tablet {
				min-height: 90px;
				padding-right: 45px;
			}
		}
		ul {
			padding: 20px 5px 15px 15px;
			margin-bottom: 0;
		}
	}
}

a.address {
	display: inline-block;
	font-weight: 500;
	color: #999;
	line-height: 1;
	&:before {
		font-family: 'ElegantIcons';
		content: "\e01c";
		margin-right: 5px;
		display: inline-block;
	}
	&:hover {
		color: $color-1;
	}
}

.loc_open,.loc_closed {
	position: relative;
	top:-2px;
	@include fontSize(11px);
	font-weight: 600;
	padding: 2px 8px;
	line-height: 1;
	@include border-radius(3px);
}

.loc_open {
	color: #32a067;
	border: 1px solid #32a067;
}
.loc_closed {
	color: #eb5c65;
	border: 1px solid #eb5c65;
}

.cat_star {
	i {
		margin-right: 2px;
		color: $color-5;
	}
}

.rating {
	color: #ccc;
	.voted {
		color: $color-5;
	}
	i {
		margin-right: 2px;
	}
}

.box_list {
	background-color: #fff;
	display: block;
	position: relative;
	margin: 0 0 30px 0;
	min-height: 310px;
	@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.10));
	a.wish_bt {
		position: absolute;
		right: 15px;
		top: 15px;
		z-index: 1;
		@include rgba(bg, #000, 0.1);
		padding: 9px 10px;
		display: inline-block;
		color: #555;
		line-height: $line-height-none;
		@include border-radius(3px);
		&:after {
			font-family: 'ElegantIcons';
			content: "\e030";
			@include transition-default (all,0.5s, ease);
		}
		&.liked:after{
			content: "\e089";
			color: $color-1;
		}
		&:hover.liked:after{
			color: $color-1;
		}
		&:hover:after {
			content: "\e089";
			color: #999;
		}
	}
	figure {
		margin-bottom: 0;
		overflow: hidden;
		position: relative;
		min-height: 310px;
		small {
			position: absolute;
			@include rgba(bg, #000, 0.6);
			right: 20px;
			top:20px;
			text-transform: uppercase;
			color: #ccc;
			font-weight: 600;
			@include border-radius(3px);
			padding: 7px 10px 4px 10px;
			line-height: 1;
			z-index: 9;
			@include tablet {right: 15px; top: 15px;}
		}
		@include tablet {min-height: 220px; max-height: 220px; height: 220px;}
		.read_more {
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -12px;
			@include transform (translateY(10px));
			text-align: center;
			opacity: 0;
			visibility: hidden;
			width: 100%;
			-webkit-transition: all 0.6s;
			transition: all 0.6s;
			z-index:2;
			span {
				@include rgba(bg, #fff, 0.8);
				@include border-radius(20px);
				display: inline-block;
				color: #222;
				@include fontSize(12px);
				padding: 5px 10px;
			}
		}
		&:hover .read_more {
			opacity: 1;
			visibility: visible;
			@include transform (translateY(0));
		}
		a {
			img {
				position: absolute;
				left: 50%;
				top: 50%;
				@include transform (translate(-50%, -50%) scale(1));
				@include backface-visibility (hidden);
				height: 350px;
				width: auto;
				max-width: none;
				@include transition-default;
				@include tablet {max-width: inherit; height: 450px; width: auto;}
				@include mobile {height: 350px;}
			}
			&:hover img {
				@include transform (translate(-50%, -50%) scale(1.1));
			}
		}
	}
	.wrapper {
		padding: 30px 30px 20px 30px;
		min-height: 245px;
		@include tablet {min-height:inherit;}
		h3 {
			@include fontSize(20px);
			margin-top: 0;
		}
	}
	small {
		text-transform: uppercase;
		color: #999;
		font-weight: 600;
	}
	ul {
		padding: 20px 15px 20px 30px;
		border-top: 1px solid $border-color-2;
		margin-bottom: 0;
		li {
			display: inline-block;
			margin-right: 15px;
			.score {
				margin-top: -10px;
			}
			&:last-child {
				margin-right: 0;
				float: right;
			}
		}
	}
}

#list_sidebar {
	.box_list {
		min-height: 330px;
		figure {
			min-height: 330px;
			@include tablet {
				min-height: 200px;
				max-height: 200px;
				height: 200px;
			}
			a {
				img {
					height: 350px;
					@include tablet {
						max-width: 100%;
						height: auto;
						width: inherit;
					}
				}
			}
		}
		.wrapper {
			min-height: 265px;
			@include tablet {
				min-height: inherit;
			}
		}
	}
}
.layout_view {
	@include border-radius(3px);
	border: 1px solid rgba(0,0,0,0.08);
	padding: 4px 8px 3px 8px;
	a {
		color: rgba(0,0,0,.5);
		&.active {
			color: rgba(0,0,0,0.8);
		}
		&:hover {
			color: $color-1;
		}
	}
}

.switch-field {
	overflow: hidden;
	border: 1px solid rgba(0,0,0,0.08);
	@include border-radius(3px);
	input {
		position: absolute!important;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		width: 1px;
		border: 0;
		overflow: hidden;
		&:checked+label {
			@include box-shadow (none);
			color: rgba(0,0,0,0.9);
		}
	}
	label {
		float: left;
		display: inline-block;
		min-width: 65px;
		color: rgba(0,0,0,.5);
		font-weight: 600;
		@include fontSize(12px);
		text-align: center;
		text-shadow: none;
		padding: 10px 8px 8px 8px;
		line-height: 1;
		border-right: 1px solid rgba(0,0,0,0.08);
		@include transition-default (all,0.2s,ease-in-out);
		@include extra-small-mobile {min-width: inherit;}
		margin: 0;
		&:first-of-type {
			border-left: 0;
		}
		&:last-of-type {
			border-right: 0;
		}
		&:hover {
			cursor: pointer;
			color: $color-1;
		}
	}
}

#filters_col{
	background-color:#fff;
	padding:20px 20px 15px 20px;
	margin-bottom:25px;
	border: 1px solid $border-color-2;
    label{
        color:$heading-color;
        font-weight:normal;
    }
}

a#filters_col_bt {
	display:block;
	color:$heading-color;
	position:relative;
    @include fontSize(16px);
	font-weight: 600;
    &:before{
        font-family: "ElegantIcons";
        content: "\66";
		@include fontSize(26px);
        position:absolute;
        right:0;
        top:0;
        line-height:$line-height-none;
		font-weight: normal;
    }
}

.filter_type {
    h6 {
        border-top: 1px solid $border-color-2;
        margin: 15px 0;
        padding: 15px 0 0 0;
        @include fontSize(13px);
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 15px 0;
        li {
            margin-bottom: 5px;
			small {
                float: right;
				position: relative;
				top:4px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.category_filter {
	border-bottom: 1px solid #ddd;
	margin-bottom: 25px;
	padding-bottom: 10px;
	.container_radio {
		display: inline-block;
		margin-right: 15px;
	}
}

a.boxed_list {
	text-align: center;
	padding: 30px;
	border: 1px solid $border-color-2;
	display: block;
	@include transition-default;
	margin-bottom: 25px;
	i {
		@include fontSize(60px);
		margin-bottom: 15px;
	}
	h4 {
		@include fontSize(18px);
		color: $heading-color;
	}
	p {
		color: #777;
		margin-bottom: 0;
	}
	&:hover i{
			color:$color-3;
		}
}
a.btn_search_mobile {
	background: url(../img/search.svg) no-repeat center center;
	width: 21px;
	height: 21px;
	float: right;
	display: none;
	@include tablet {
		display: block;
	}
	&.map_view {
		display: block;
	}
}
#search_mobile {
	overflow: auto;
	transform: translateX(105%);
	top:0;
	right:0;
	bottom: 0;
	width: 50%;
	height: 100%;
	position:fixed;
	background: #fff url(../img/bg_aside.svg) no-repeat center right;
	@include background-size(cover); 
	padding: 60px 30px 30px 30px;
	z-index:9999999999999999!important;
	-webkit-box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.55);
	-moz-box-shadow:    1px 0px 5px 0px rgba(50, 50, 50, 0.55);
	box-shadow:         1px 0px 5px 0px rgba(50, 50, 50, 0.55);
	-webkit-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
	&.show {transform: translateX(0);}
	@include mobile {width: 100%; padding: 45px 30px 30px 30px;}
	input.form-control {
		border: 1px solid $border-color-3;
		box-shadow: none;
	}
	.custom-search-input-2{
		box-shadow: none;
		input[type='submit'] {
			margin: 20px 0 0 0;
		}
	}
	.form-group{
		margin-bottom: 5px;
	}
	.nice-select {
		border: 1px solid $border-color-3;
	}
	a.side_panel {
		@include fontSize(32px);
		display: block;
		position: absolute;
		right: 10px;
		top:0;
		color: #999;
		&:hover {color:$color-1;}
	}
}

.search_mob {
	a {
		display: none;
		width: 22px;
		height: 24px;
		text-indent: -9999px;
		position: relative;
		@include tablet {
			display: block;
		}
		color: $color-2;
		&:hover {
			color: $color-1;
		}
		&:before {
			font-family: 'themify';
			content: "\e610";
			text-indent: 0;
			position: absolute;
			left: 0;
			top: 0;
			line-height: 1;
			font-weight: 600;
			@include fontSize(21px);
		}
	}
}

.search_mob_wp {
	padding: 10px 0 10px 0;
	display: none;
	width: 100%;
	.nice-select {
		margin-bottom: 5px;
		border: none;
	}
	.form-control {
		margin-bottom: 10px;
	}
	input[type='submit'] {
		background-color: $color-5;
		color: #222;
		&:hover {
			background-color: $color-3;
			color: #fff;
		}
	}
}

/* Opacity mask when left open */
.layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    z-index: 100;
    min-height: 100%;
    background-color: #000;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
    -moz-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
    -ms-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
    -o-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
    opacity: 0;
    visibility: hidden;
}
.layer-is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
    -moz-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
    -ms-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
}

/*-------- 2.4 Detail page --------*/
.hero_in {
	width: 100%;
	height: 450px;
	position: relative;
	overflow: hidden;
	@include mobile {height: 350px;}
	&.shop_detail {
		height: 550px;
		@include mobile {height: 350px;}
		&:before {
			background: url(../img/hero_in_shop_detail.jpg) center center no-repeat; 
			@include background-size(cover); 
		}
		.wrapper {@include rgba(bg, #000, 0.2);}
	}
	&.hotels_detail {
		height: 550px;
		@include mobile {height: 350px;}
		&:before {
			background: url(../img/hero_in_hotels_detail.jpg) center center no-repeat; 
			@include background-size(cover); 
		}
		.wrapper {@include rgba(bg, #000, 0.2);}
	}
	&.restaurant_detail {
		height: 550px;
		@include mobile {height: 350px;}
		&:before {
			background: url(../img/hero_in_restaurants_detail.jpg) center center no-repeat; 
			@include background-size(cover); 
		}
		.wrapper {@include rgba(bg, #000, 0.2);}
	}
	&:before {
		animation: pop-in 5s .1s cubic-bezier(0, 0.5, 0, 1) forwards;
		content: "";
		opacity: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
	}
	.wrapper {
		height: 100%;
	}
	a.btn_photos {
		position: absolute;
		left: 20px;
		bottom:20px;
		background-color: #fff;
		@include border-radius(3px);
		@include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.20));
		line-height: 1;
		padding: 10px 15px;
		color: #444;
		font-weight: 500;
		&:hover{
			color: $color-1;
		}
	}
}

/* Animations */
@keyframes pop-in {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
	@include transform (scale(1.1));
  }
}
.secondary_nav {
	background: $color-1;
	padding: 15px 0;
	&.is_stuck {
		z-index: 99;
		width: 100%!important;
		left: 0;
	}
	ul {
		margin-bottom: 0;
		li {
			display: inline-block;
			margin-right: 20px;
			font-weight: 500;
			@include fontSize(16px);
			a {
				color: rgba(255, 255, 255, .5);
				&:hover {
					color: #fff;
					opacity: 1;
				}
				&.active{
					color: rgba(255, 255, 255, 1);
				}
			}
			&:last-child {
				display: none;
				@include extra-small-mobile {display: inline-block;}
			}
		}
	}
}

/* Header with carousel detail page */
#carousel_in {
	.owl-dots {
		position: absolute;
		bottom:10px;
		width: 100%;
		text-align: center;
		z-index: 5;
		@include mobile {bottom: 0;}
	}
}

.detail_title_1 {
	margin-bottom: 25px;
	h1 {
       @include fontSize(32px);
	   margin: 0;
    }
     ul {
		   float: right;
            margin: 10px 0 0 0;
            li {
                display: inline-block;
                margin-right: 20px;
				font-weight: 500;
            }
        }
}

section#description, section#reviews {
	border-bottom: 3px solid $border-color-3;
	margin-bottom: 30px;
	h2 {
		@include fontSize(24px);
	}
	h3 {
		@include fontSize(21px);
	}
	h4 {
		@include fontSize(18px);
	}
	hr {
		border-color: $border-color-3;
	}
}
section#reviews {
	border-bottom: none;
}
#review_summary {
	text-align: center;
	background-color: $color-3;
	color: #fff;
	padding: 20px 10px;
	@include border-radius(3px 3px 3px 0);
	@include tablet {
		margin-bottom: 15px;
	}
	strong {
		@include fontSize(42px);
		display: block;
		line-height: $line-height-none;
	}
	em {
		font-style: normal;
		font-weight: 500;
		display: block;
	}
}

.reviews-container {
	.progress {
		margin-bottom: 12px;
	}
	.progress-bar {
		background-color: $color-3;
	}
	.review-box {
		position: relative;
		margin-bottom: 25px;
		padding-left: 100px;
		min-height: 100px;
		@include mobile {
			padding-left: 0;
		}
	}
	.rev-thumb {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 80px;
		height: 80px;
		background: #ffffff;
		@include border-radius(3px);
		overflow: hidden;
		img {
			width: 80px;
			height: auto;
		}
		@include mobile {
			position: static;
			margin-bottom: 10px;
		}
	}
	.rev-content {
		position: relative;
		padding: 25px 25px 1px 25px;
		@include border-radius(3px);
		background-color: #fff;
	}
	.rev-info {
		@include fontSize(12px);
		font-style: italic;
		color: #777;
		margin-bottom: 10px;
	}
}
.box_detail {
	background-color: #fff;
	padding: 25px 25px 15px 25px;
	@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.10));
	@include border-radius(3px);
	margin-bottom: 30px;
	.price {
		line-height: $line-height-none;
		border-bottom: 1px solid $border-color-2;
		margin: 0 -25px 25px -25px;
		padding: 0 25px 15px 25px;
		>span {
			@include fontSize(31px);
			font-weight: 600;
			>small {
				@include fontSize(11px);
				font-weight: 500;
			}
		}
		.score {
			float: right;
			margin-top: -5px;
		}
	}
	h3 {
		@include fontSize(20px);
		margin: 25px 0 10px 0;
	}
	ul {
		margin-bottom: 0;
		li {
			margin-bottom: 5px;
			i {
				margin-right: 8px;
			}
		}
	}
	figure {
		position: relative;
		background-color: #000;
		img {
			opacity: 0.8;
		}
		span {
			position: absolute;
			display: block;
			left: 0;
			bottom: 10px;
			text-align: center;
			width: 100%;
			color: #fff;
			font-weight: 600;
		}
		a i {
			@include rgba(bg, #000, 0.6);
			display: block;
			width: 60px;
			height: 60px;
			color: #fff;
			@include border-radius(50%);
			line-height: 60px;
			text-align: center;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -30px 0 0 -30px;
			@include fontSize(42px);
			@include transition-default;
			z-index: 9;
		}
		&:hover i {
			@include transform (scale(1.3));
		}
	}
}

/* Media Queries */
@media screen and (min-width: 564px) {
	.daterangepicker {
		width: 500px!important;
	}
}
@media screen and (min-width: 564px) {
	.daterangepicker.single {
		width:auto!important;
	}
}

/* Booking form */
.booking {
	input {
		font-weight: 500;
		height: 45px;
		&:focus {
			box-shadow: none;
			border: 1px solid $border-color-3;
		}
	}
	select {
		display: none;
	}
	.dropdown {
		background-color: #fff;
		@include fontSize(14px);
		@include border-radius(3px);
		border: 1px solid $border-color-3;
		padding-top: 10px;
		padding-bottom: 10px;
		margin: 0 0 10px 0;
		@include tablet {
			height: inherit;
		}
	}
	.nice-select .current {
		font-weight: 500;
		color: #6f787f;
	}
	.custom-select-form {
		.nice-select {
			&:active,
			&:focus {
				border-color: $border-color-3;
				outline: 0;
				box-shadow: none;
			}
		}
		select {
			display: none;
		}
	}
	.form-group {
		margin: 0 0 10px 0;
		i {
			@include border-radius(3px);
			@include fontSize(18px);
			position: absolute;
			background-color: #fff;
			height: 43px;
			top: 1px;
			right: 1px;
			padding-right: 10px;
			display: block;
			width: 20px;
			box-sizing: content-box;
			line-height: 43px;
			z-index: 9;
			color: #999;
			&.loader{
				background-color: transparent;
				color: #fff;
			}
		}
		i.icon_lock_alt {
			top: 36px;
		}
		i.ti-pencil {
			top: 1px;
			height: 20px;
		}
	}
	textarea {
		font-weight: 500;
		height: 100px;
		padding-right: 25px;
		&:focus {
			box-shadow: none;
			border: 1px solid $border-color-3;
		}
	}
}

.dropdown {
	position: relative;
	text-align: left;
	padding:  15px 10px 0 15px;
	@include tablet {
		background-color: #fff;
		@include border-radius(3px);
		height: 50px;
	}
	a {
		color: #727b82;
		font-weight: 500;
		transition: all 0.3s;
		display: block;
		position: relative;
		&:after {
			font-family: 'ElegantIcons';
			content: "\33";
			@include fontSize(24px);
			color: #999;
			font-weight: 500;
			@include transition-default;
			position: absolute;
			right: 0;
			top:-8px;
		}
	}
	&.show a:after { transform: rotate(180deg); }
	.dropdown-menu {
		@include fontSize(14px);
		@include transition-default (opacity,0.5s, ease);
		margin-top: 16px;
		background: #fff;
		border: 1px solid $border-color-3;
		@include border-radius(3px);
		box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
		padding: 15px 15px 0 15px;
		white-space: normal;
		width: 280px;
		input:focus {
			outline: none!important;
			border: none!important;
			@include box-shadow (none);
		}
	}
}

 /*Quantity incrementer input*/
.dropdown-menu-content {
	display: flex;
		margin-bottom: 10px;
	label {
		display: block;
		flex: 1;
		line-height: 40px;
		color: #727b82;
	}
}
.qty-buttons {
	position: relative;
	height: 38px;
	display: inline-block;
	width: 140px;
}

input.qty {
	width: 100%;
	text-align: center;
	border: none!important;
	color: #495057;
	@include fontSize(16px);
	height: 40px;
}

input.qtyminus,
input.qtyplus {
	position: absolute;
	width: 36px;
    height: 36px;
	border: 0;
	outline: none;
	cursor: pointer;
	-webkit-appearance: none;
	@include border-radius(50px);
}
input.qtyplus {
	background:#f2f2f2 url(../img/plus.svg) no-repeat center center;
	right: 3px;
	top: 1px;
	text-indent: -9999px;
	box-shadow: none;
	&:focus {
		border: none;
	}
}

input.qtyminus {
	background: #f2f2f2 url(../img/minus.svg) no-repeat center center;
	left: 3px;
	top: 1px;
	text-indent: -9999px;
	box-shadow: none;
	&:focus {
		border: none;
	}
}

#qty_total {
	display: inline-block;
	font-size: 11px;
	font-weight: 600;
	line-height: 20px;
	text-align: center;
	position: relative;
	top: 0;
	left: 2px;
	height: 18px;
	width: 18px;
	background-color: #66676b;
	border-radius: 50%;
	color: #fff;
}

.rotate-x {
	animation-duration: .5s;
	animation-name: rotate-x;
}

@keyframes rotate-x {
	from {
		transform: rotateY(0deg);
	}
	to {
		transform: rotateY(360deg);
	}
}

ul.hotel_facilities {
	list-style: none;
	margin:0;
	padding: 0;
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
	li {
		margin-bottom: 10px;
		display: inline-block;
		width: 100%;
		img {
			margin-right: 10px;

		} 
	} 
}

.room_type {
	padding: 10px 0 25px 0;
	&.last {
		padding-bottom: 0;
	}
	h4 {
		@include mobile {margin-top: 25px;}
	}
} 

.opening {
	@include rgba(bg, $color-1, 0.05);
    padding: 25px 25px 25px 80px;
    position: relative;
    @include border-radius(3px);
    @include mobile {padding: 15px;}
	i {
        position: absolute;
        left: 25px;
        top:25px;
        @include fontSize(36px);
        color: #555;
        @include mobile {display: none;}
	}
	h4 {
		margin-top: 10px;
        @include mobile {margin-top: 0;}
	}
    ul {
		margin: 0;
        padding: 0;
        li {
           span {
                display: inline-block;
               float: right;
            }
        }
	}
} 

ul.menu_list {
    list-style: none;
    padding: 0;
    margin: 0 0 0 0;
    li {
        position: relative;
        padding: 5px 0 25px 120px;
        clear: both;
        @include fontSize(13px);
        h6 {
            @include fontSize(14px);
            border-bottom: 1px dashed #ddd;
            padding-bottom: 5px;
            span {
                float: right;
            }
        }
        .thumb {
            width: 100px;
            height: 100px;
            position: absolute;
            left: 0;
            top: 0;
            overflow: hidden;
            border: 1px solid #ddd;
            padding: 5px;
            img {
                width: 88px;
                height: auto;
                text-align: center;
            }
        }
    }
}

.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
    span {
        font-size: 10px;
        font-weight: bold;
        color: #FFF;
        text-transform: uppercase;
        text-align: center;
        line-height: 20px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: 100px;
        display: block;
        position: absolute;
        top: 19px;
        right: -21px;
        &.open {
            background: #79A70A;
            &::before {
                content: "";
                position: absolute;
                left: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid #5d8108;
                border-right: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-top: 3px solid #5d8108;
            }
            &::after {
                content: "";
                position: absolute;
                right: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid transparent;
                border-right: 3px solid #5d8108;
                border-bottom: 3px solid transparent;
                border-top: 3px solid #5d8108;
            }
        }
        &.closed {
            background: #ca3b41;
            &::before {
                content: "";
                position: absolute;
                left: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid #7d2528;
                border-right: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-top: 3px solid #7d2528;
            }
            &::after {
                content: "";
                position: absolute;
                right: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid transparent;
                border-right: 3px solid #7d2528;
                border-bottom: 3px solid transparent;
                border-top: 3px solid #7d2528;
            }
        }
    }
}

/*Map lisiting --------------- */
.full-height {
	height: 100%;
	@include tablet {
		height: auto;
	}
}

.row-height {
	height: 100%;
	min-height: 100%;
	padding-top: 58px;
	@include tablet {
		height: auto;
		padding-top: 48px;
	}
}

.map-right {
	height: 100%;
	min-height: 100%;
	padding: 0px;
	position: fixed;
	top:0;
	right: 0;
	z-index: 0;
	z-index: 9999;
	@include tablet {
		height: 400px;
		position: static;
		min-height: inherit;
	}
}

#map_right_listing {
	height: 100%;
	width: 100%;
}

.content-left {
	height: 100%;
	min-height: 100%;
	overflow-y: scroll;
	background-color: $body-color;
	@include tablet {
		padding-top: 0;
	}
}


/*-------- 2.5 Login/register --------*/
#login_bg, #register_bg {
	background:#444 url(../img/access_bg.jpg) center center no-repeat fixed;
	@include background-size(cover);
	min-height: 100vh;
	width: 100%;
}
#register_bg {
	background:#444 url(../img/access_bg.jpg) center center no-repeat fixed;
	@include background-size(cover);
}

#login, #register {
	figure {
		text-align: center;
		border-bottom: 1px solid $border-color-2;
		margin: -45px -60px 25px -60px;
		padding: 20px 60px 25px 60px;
		@include mobile {margin: -30px -30px 20px -30px; padding: 15px 60px 20px 60px;}
	}
	aside {
		width: 430px;
		@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.50));
		padding: 45px 60px 60px 60px;
		margin-left: -215px;
		position: absolute;
		left: 50%;
		top: 0;
		overflow-y: auto;
		background-color: #fff;
		min-height: 100vh;
		@include mobile {width: 100%; padding: 30px; left: 0; margin: 0;}
		form {
			margin-bottom: 60px;
			display: block;	
			.form-group {
			input {
				  padding-left: 40px;
				}
			  i {
				  @include fontSize(21px);
				  position: absolute;
				  left: 12px;
				  top: 34px;
				  color: #ccc;
				  width: 25px;
				  height: 25px;
				  display: block;
				  font-weight: 400!important;
				}
			}
		}
		.copy {
			text-align: center;
			position: absolute;
			height: 30px;
			left: 0;
			bottom: 30px;
			width: 100%;
			color: #999;
			font-weight: 500;
		}
	}
}

.access_social {
	margin-top: 45px;
	@include mobile {margin-top: 30px;}
}
.divider {
	text-align: center;
	height: 1px;
	margin: 30px 0 15px 0;
	background-color: $border-color-2;
	span {
		position: relative;
		top:-20px;
		background-color: #fff;
		display: inline-block;
		padding: 10px;
		font-style: italic;
}
}
a.social_bt {
	@include border-radius(3px);
	text-align: center;
	color: #fff;
	min-width: 200px;
	margin-bottom: 10px;
	display: block;
	padding: 12px;
	line-height: $line-height-none;
	position: relative;
	@include transition-default;
	cursor: pointer;
	&:hover {
		-webkit-filter: brightness(115%);
		filter: brightness(115%);
	}
	&.facebook,
	&.google,
	&.linkedin {
		&:before {
			font-family: 'ElegantIcons';
			position: absolute;
			left: 12px;
			top: 10px;
			@include fontSize(16px);
			color: #fff;
		}
	}
	&.facebook {
		background-color: #3B5998;
		&:before {
			content: "\e093";
		}
	}
	&.google {
		background-color: #DC4E41;
		&:before {
			content: "\e096";
			top: 12px;
		}
	}
	&.linkedin {
		background-color: #0077B5;
		&:before {
			content: "\e09d";
		}
	}
}

/*Password strength */
#pass-info {
	width: 100%;
	margin-bottom: 15px;
	color: #555;
	text-align: center;
	@include fontSize(12px);
	padding: 5px 3px 3px 3px;
	@include border-radius(3px);
	&.weakpass {
		border: 1px solid #FF9191;
		background: #FFC7C7;
		color: #94546E;
	}
	&.stillweakpass {
		border: 1px solid #FBB;
		background: #FDD;
		color: #945870;
	}
	&.goodpass {
		border: 1px solid #C4EEC8;
		background: #E4FFE4;
		color: #51926E;
	}
	&.strongpass {
		border: 1px solid #6ED66E;
		background: #79F079;
		color: #348F34;
	}
	&.vrystrongpass {
		border: 1px solid #379137;
		background: #48B448;
		color: #CDFFCD;
	}
}

/* Checkbox style */
.container_check {
	display: block;
	position: relative;
	@include fontSize(14px);
	padding-left: 30px;
	line-height: 1.4;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		&:checked~.checkmark {
			background-color: $color-1;
			border: 1px solid transparent;
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border: 1px solid $border-color-3;
		@include border-radius(3px);
		@include transition-default;
		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 7px;
			top: 3px;
			width: 5px;
			height: 10px;
			border: solid white;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}

.container_check input:checked~.checkmark:after {
	display: block;
}

/* Radio buttons */
.container_radio {
	display: block;
	position: relative;
	@include fontSize(15px);
	padding-left: 30px;
	line-height: 1.3;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		&:checked~.checkmark:after {
			opacity: 1;
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border: 1px solid $border-color-4;
		border-radius: 50%;
		&:after {
			display: block;
			content: "";
			position: absolute;
			opacity: 0;
			@include transition-default;
			top: 3px;
			left: 3px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: $color-1;
			@include transition-default;
		}
	}
}

/*-------- 2.6 Checkout --------*/
.step .nav-link {
  display: block;
  padding: 0 15px;
	height: 30px;
	@include fontSize(15px);
	color: #444;
}

.step .nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.step .nav-link.disabled {
  color: #6c757d;
}

.step .nav-tabs {
  border: none;
  border-bottom: 2px solid #dee2e6;
}

.step .nav-tabs .nav-item {
  margin-bottom: -2px;
}

.step .nav-tabs .nav-link {
   border: none;
}

.step .nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.step .nav-tabs .nav-link.active,
.step .nav-tabs .nav-item.show .nav-link {
  border-bottom: 2px solid $color-1;
  color: $color-1;
	background-color: transparent;
}

.step .tab-content.checkout {
	padding: 15px 0 0 0;
	hr {
		margin: 10px 0;
	}
	.form-group{
		margin-bottom: 10px;
	}
	.nice-select {
		margin-bottom: 0;
	}
	.social_bt {
		margin-bottom: 10px;
	}
}

#other_addr_c {
	display: none;
}

.step {
	margin-bottom: 30px;
	@include tablet {margin-bottom: 35px;}
	& h3:before {
		width: 0;
		height: 0;
		border-top: 20px inset transparent;
		border-bottom: 20px inset transparent;
		border-left: 10px solid #f8f8f8;
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		@include mobile {border: none;}
	}
	& h3:after {
		width: 0;
		height: 0;
		border-top: 20px inset transparent;
		border-bottom: 20px inset transparent;
		border-left: 10px solid #333;
		position: absolute;
		content: "";
		top: 0;
		right: -10px;
		z-index: 2;
		@include mobile {border: none;}
	}
	&.first h3:before,
	&.last h3:after {
		border: none;
	}
	h3 {
		padding: 10px 12px 10px 20px;
		background: #333;
		position: relative;
		margin-bottom: 15px;
		color: #fff;
		@include fontSize(16px);
		@include mobile {margin-left: -15px; margin-right: -15px; padding-left: 15px;}
	}
	#forgot_pw {
		@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.10));
	}
}
.payments{
	ul {
		list-style: none;
		margin: 0 0 30px 0;
		padding: 0;
		li {
			border-bottom: 1px solid $border-color-2;
			margin-bottom: 8px;
			a.info {
				display: inline-block;
				float: right;
				color: #444;
				&:hover {
						color: $color-1;
					}
				&:after {
					font-family: 'themify';
					content: "\e718";
					position: relative;
					right: 0;
					top: 0;
					@include fontSize(15px);
				}
			}
		}
	}
}

.payment_info{
	figure img {
		height: 20px;
		width: auto;
	}
	p {
		@include fontSize(13px);
	}
}

.box_general {
	&.summary {
		background-color: #fff;
		padding-bottom: 20px;
		ul {
			list-style: none;
			margin: 0 0 20px 0;
			padding: 0;
			li {
				border-bottom: 1px solid $border-color-2;
				margin-bottom: 8px;
				padding-bottom: 6px;
				font-weight: 500;
				&:last-child {
					font-weight: 700;
					border-bottom: none;
					color: red;
				}
			}
		}
		label.container_check {
			@include fontSize(12px);
			padding-top: 4px;
		}
	}
}

#confirm {
	text-align: center;
	background-color: #f8f8f8;
	padding: 60px 15px;
}
@-webkit-keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }
    100% {
        stroke-dashoffset: 0
    }
}
@-ms-keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }
    100% {
        stroke-dashoffset: 0
    }
}

@-webkit-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }
    100% {
        stroke-dashoffset: 480px
    }
}

@-ms-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }
    100% {
        stroke-dashoffset: 480px
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }
    100% {
        stroke-dashoffset: 480px
    }
}
.inlinesvg .svg svg {
    display: inline;
}
.icon--order-success svg path {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards
}
.icon--order-success svg circle {
    -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
    animation: checkmark-circle 0.6s ease-in-out backwards
}
/*-------- 2.7 Account --------*/
.box_account {
    margin-bottom: 25px;
    h3 {
        @include fontSize(21px);
        padding-left: 30px;
        height: 30px;
        padding-top: 5px;
        display: inline-block;
		margin-bottom: 15px;
        &.client {
            background: url(../img/user.svg) center left no-repeat;
        }
        &.new_client {
            background: url(../img/new_user.svg) center left no-repeat;
        }
        }
    .form_container {
		@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.10));
        background-color: #fff;
        padding: 25px;
        position: relative;
        hr {
            margin: 0 0 10px 0;
        }
        a.social_bt {
            margin-bottom: 0;
            min-width: inherit;
            @include tablet {margin-bottom: 5px;}
        }
        .divider {
            margin-top: 20px;
            span {
                background-color: #fff;
            }
        }
        .form-group {
            margin-bottom: 10px;
        }
        #forgot_pw{
           height: 100%!important;
            min-height: inherit;
           background-color: #fff;
           padding: 25px;
        }
    }
}

/*-------- 2.8 Map styles --------*/

/* Cluster styles */
.cluster img {display:none!important;}

.cluster-visible {
	text-align: center;
	font-size: 15px !important;
	color: #fff !important;
	font-weight: 500;
	border-radius: 50%;
	width: 36px !important;
	height: 36px !important;
	line-height: 36px !important;
	background-color: $color-1!important;
	background-image: none!important;
}

.cluster-visible:before {
	border: 7px solid $color-1;
	opacity: 0.2;
	box-shadow: inset 0 0 0 4px $color-1;
	content: '';
	position:absolute;
	border-radius: 50%;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation: cluster-animation 2.5s infinite;
}

@keyframes cluster-animation {
	0%,100% {
		transform:scale(1.3) rotate(0deg)
	}
	50% {
		transform:scale(1.5) rotate(90deg)
	}
}

.map {
	width: 100%; 
	height: 500px;
	&.map_single {
		height: 400px;
	}
}
.infoBox {
	-webkit-animation: fadeIn 0.9s;
	animation: fadeIn 0.9s;
	padding-right: 50px;
	>img {
		position: absolute!important;
		right: 60px!important;
		top: 10px!important;
		z-index: 9999;
		width: 20px;
		height: 20px;
		display: block;
		cursor: pointer;
	}
}

@-webkit-keyframes fadeIn {
    from {opacity: 0; }
    to {opacity: 1; }
}
.marker_info {
	width: 240px;
	height: 270px;
	border-radius: 5px;
	text-align: left;
	background: #000;
	background: rgba(255, 255, 255, 1);
	position: relative;
	z-index: 999;
	font-family: "Poppins", Helvetica, sans-serif;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
	img {
		display: block;
		@include border-radius(5px 5px 0 0);
	}
	h3 {
		@include fontSize(16px);
		line-height: 1.1;
		font-weight: 500;
		margin-top: 3px;
		color: #444;
	}
	em {
		display: inline-block;
		@include fontSize(12px);
		color: #999;
		font-style: normal;
	}
	span {
		display: block;
		padding: 15px 20px 0 20px;
		@include fontSize(13px);
		line-height: 1.2;
		color: #fff;
		position: relative;
		strong {
			display: block;
			font-weight: 500;
			
		}
	}
	&:after{
		right: 100%;
		top: 56%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(0, 0, 0, 0);
		border-right-color: rgba(255, 255, 255, 1);
		border-width: 12px;
		margin-top: -12px;
	}
}
a.btn_infobox_detail{
	position:absolute;
	top:20px;
	right:15px;
	width:20px;
	height:20px;
}
a.btn_infobox_detail:before, .btn_infobox_get_directions:before,a.btn_infobox_phone:before{
	 font-style: normal;
  	font-weight: normal;
  	font-family: "ElegantIcons";
  	@include fontSize(20px);
  	cursor:pointer;
}
a.btn_infobox_detail:before{
  content: "\70";
  color:#ccc;
	&:hover {
	  color: $color-1;
	}
}
.btn_infobox_get_directions, a.btn_infobox_phone{
	border:none;
	display:inline-block;
	font-weight:500;
	color: $color-1;
	background:none;
	cursor:pointer;
	@include fontSize(13px);
	position:relative;
	padding-left:20px;
	margin-bottom:10px;
	outline:none;
	&:focus {
	  outline:none;
	}
}
.btn_infobox_get_directions:hover, a.btn_infobox_phone:hover{color:#333;}
.btn_infobox_get_directions:before{
  @include fontSize(14px);
  content: "\e080";
  position:absolute;
  left:0;
  top:0;
}
a.btn_infobox_phone:before{
  font-size:14px;
  content: "\e090";
  position:absolute;
  left:0;
  top:-2px;
}
span.infobox_rate{
	display:inline-block;
	margin:-44px 0 0 -20px;
	float:left;
	background-color:$color-2;
	padding:5px 8px;
	font-weight:500;
	text-align:left;
	@include fontSize(12px);
}

/*-------- 2.9 Contacts--------*/
#map_contact{
	width: 100%;
	height: 450px;
	@include tablet {height: 350px;}
}
iframe#map_iframe{
	width: 100%;
	height: 450px;
	border: 0;
	@include tablet {height: 400px;}
}
.box_contacts {
	background-color: #fff;
	padding: 25px;
	@include border-radius(3px);
	margin-bottom: 15px;
	text-align: center;
	@include fontSize(15px);
	h2 {
		font-weight: 500;
		margin: 10px 0 10px 0;
		@include fontSize(21px);
	}
	i {
		@include fontSize(46px);
		color: $color-1;
	}
	a {
		color: $body-text-color;
		&:hover {
			color: $color-1;
			text-decoration: underline;
		}
	}
}
.error_message{
	font-weight: 500;
	color: #e3464c;
}
/*-------- 2.10 404 page --------*/
#error_page {
	background: $color-2 url(../img/pattern_1.svg) center bottom repeat-x;
	@include mobile {background-size: auto 300px;}
	color: #fff;
	height: 600px;
	.wrapper {
		@include background-gradient(rgba(0, 0, 0, 0.6), transparent, 'vertical');
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	h2 {
		margin-top: 30px;
		@include fontSize(140px);
		color: #fff;
		text-transform: uppercase;
		font-weight: bold;
		line-height: $line-height-none;
		i {
			color: $color-5;
		}
		@include mobile {
			@include fontSize(72px);
		}
	}
	p {
		@include fontSize(21px);
		@include mobile {
			@include fontSize(18px);
		}
	}
	@include mobile {
		padding: 0;
		height: 500px;
	}
}

.search_bar_error {
	position: relative;
	margin-bottom: 60px;
	input[type='text'] {
		border: 0;
		height: 50px;
		@include border-radius(3px);
		padding-left: 15px;
		@include box-shadow (0px 0px 50px 0px rgba(0, 0, 0, 0.15));
	}
	input[type='submit'] {
		position: absolute;
		right: -1px;
		color: #222;
		font-weight: 600;
		top: 0;
		border: 0;
		padding: 0 25px;
		height: 50px;
		cursor: pointer;
		@include border-radius(0 3px 3px 0);
		background-color: $color-5;
		@include transition-default;
		&:hover {
			background-color: $color-3;
			color: #fff;
		}
	}
}

/*-------- 2.11 Media Gallery --------*/
.grid-gallery ul {
	margin: 0 0 25px 0;
	padding: 0;
	width: 100%;
	text-align: center;
	li {
		display: inline-block;
		margin: 0;
		padding: 0;
		margin: -3px;
		min-height: 100%;
		width: 25%;
		background-color: #000;
		list-style: none;
		@include tablet {width:50%;}
		@include mobile {width:100%;min-height:100%;float:none;}
		figure {
			position: relative;
			overflow: hidden;
			margin: -3px;
			img {
				width: 100%;
				height: 100%;
				@include transition-default;
			}
			&:hover img,
			&:focus img {
				 @include transform (scale(1.1));
			}
			&:hover figcaption,
			&:focus figcaption {
				opacity: 1;
			}
			&:hover figcaption .caption-content,
			&:focus figcaption .caption-content {
				@include transform (translate(0px, 0px));
			}
		}
		figcaption {
			position: absolute;
			top: 0;
			left: 0;
			padding: 15% 0;
			width: 100%;
			height: 100%;
			@include rgba(bg, #000, 0.6);
			text-align: center;
			@include fontSize(14px);
			opacity: 0;
			@include transition-default;
			.caption-content {
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -30px;
				margin-left: -100px;
				width: 200px;
				@include transform (translate(0px, 15px));
				@include transition-default;
			}
			a {
				color: #fff;
				&:hover,
				&:focus {
					color: $color-5;
				}
			}
			i {
				@include fontSize(30px);
			}
			p {
				margin-bottom: 0;
				margin-top: 10px;
				text-transform: uppercase;
				font-weight: 400;
			}
		}
	}
}

.mfp-zoom-in {
	.mfp-with-anim {
		opacity: 0;
		transition: all 0.2s ease-in-out;
		transform: scale(0.8);
	}
	&.mfp-bg {
		opacity: 0;
		transition: all 0.3s ease-out;
	}
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			transform: scale(1);
		}
		&.mfp-bg {
			opacity: 0.8;
		}
	}
	&.mfp-removing {
		.mfp-with-anim {
			transform: scale(0.8);
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}
	}
}

/*-------- 2.12 Faq --------*/
.box_style_cat {
	background: #fff;
	border: 1px solid $border-color-2;
	margin-bottom: 25px;
	@include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));
	@include tablet {display: none;}
}

ul#cat_nav {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		i {
			@include fontSize(16px);
			margin:2px 10px 0 0;
			float: left;
		}
		border-bottom: 1px solid $border-color-2;
		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
		}
		a {
			position: relative;
			color: $body-text-color;
			display: block;
			padding: 15px 10px;
			&:after {
				font-family: "fontello";
				content: "\e89b";
				position: absolute;
				right: 15px;
				top: 15px;
			}
			span {
				@include fontSize(11px);
				color: #999;
			}
			&:hover,
			&#active,
			&.active {
				color: $color-1;
			}
		}
	}
}

.hero_in form{
	@include animation-delay (1.3s);
}
a.box_topic {
	text-align: center;
	background: #fff;
	padding: 40px 30px 15px 30px;
	@include mobile {padding: 20px 20px 0 20px;}
	display: block;
	@include transition-default;
	@include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));
	color: $body-text-color;
	margin-bottom: 30px;
	overflow: hidden;
	@include border-radius(3px);
	&:hover {
		@include transform (translateY(-10px));
	}
	i {
		@include fontSize(60px);
		color: $color-3;
		margin-bottom: 10px;
		@include border-radius(50px);
		@include rgba(bg, $color-3, 0.05);
		width: 100px;
		height: 100px;
		line-height: 100px;
		margin-bottom: 15px;
	}
	h3 {
		@include fontSize(18px);
	}
}

.list_articles {
	ul {
		list-style: none;
		margin: 0;
		padding:0;
		li {
			float: left;
			width: 45%;
			margin-right: 45px;
			padding: 15px 0;
			border-bottom: 1px solid $border-color-2;
			a {
				color: $body-text-color;
				display: block;
				&:hover {
					color: $color-1;
					@include transform (translateX(5px));
				}
			}
			i {
				margin-right: 10px;
				@include fontSize(16px);
				position: relative;
				top: 2px;
			}
			@include tablet {
				float: none;
				width: 100%;
				margin-right: 0;
			}
		}
	}
}

ul.list_3 {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		margin-bottom: 0;
		position: relative;
		padding-left: 20px;
		strong {
			font-weight: 500;
		}
		&:before {
			font-family: "fontello";
			content: "\ea3e";
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

.box_general {
	border: 1px solid $border-color-2;
	padding: 25px 25px 5px 25px;
	margin-bottom: 25px;
	@include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));
}

/*-------- 2.13 Bookings --------*/
.box_booking{
	@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.10));
	background-color: #fff;
    padding: 25px;
	margin-bottom: 25px;
}
.strip_booking{
	border-bottom:1px solid #ededed;
	padding-bottom:15px;
	margin-bottom:15px;
	&.last{
		border-bottom:none;
	}
	h3{
		@include fontSize(16px);
		text-transform:uppercase;
		position:relative;
		top:10px;
		padding-left:65px;
		@include mobile {margin: 15px 0;}
		span{
			color:#999;
			display:block;
			font-size:11px !important;
		}
	}
	.date{
		background-color:#f8f8f8;
		text-align:center;
		line-height: 1;
		.month{
			background-color:$color-3;
			display:block;
			padding: 3px 0;
			color:#fff;
			text-transform:uppercase;
		}
		.day {
			padding:15px;
			margin-top:1px;
			color:#999;
			text-transform:uppercase;
			display:block;
			@include fontSize(12px);
			strong{
				@include fontSize(32px);
				font-weight:normal;
				display:block;
				color:$color-3;
				margin-bottom:3px;
			}
		}
	}
}

.strip_booking h3.hotel_booking:before, .strip_booking h3.restaurant_booking:before, .strip_booking h3.bars_booking:before{
  font-style: normal;
  font-weight: normal;
  font-family: "fontello";
  @include fontSize(24px);
  color:#999;
  position:absolute;
  border:1px solid #ededed;
  width:50px;
  height:50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left:0;
  top:-10px;
  text-align:center;
  line-height:50px;
}
.strip_booking h3.hotel_booking:before{
  content: "\ed72";
}
.strip_booking h3.restaurant_booking:before{
  content: "\ed80";
}
.strip_booking h3.bars_booking:before{
  content: "\ed54";
}

ul.info_booking{
	list-style:none;
	margin:0;
	padding:15px 25px 15px 0;
	@include fontSize(11px);
	color:#999;
	line-height:12px;
	border-right: 1px solid #ededed;
	text-align:right;
	li{
		margin-bottom:5px;
		strong{
			text-transform:uppercase;
			display:block;
			color:#555;
		}
	}
}

.booking_buttons{
	margin-top:20px;
}
a.btn_2, .btn_2, a.btn_3, .btn_3{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	background: $color-1;
	cursor: pointer;
	padding: 7px 8px;
	@include fontSize(11px);
	line-height:9px;
	font-weight: 500;
	display:block;
	outline: none;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-align:center;
	&:hover{
		background: $color-5;
		color:#444;
	}
}
a.btn_3, .btn_3{
	color:#999;
	background: #eee;
	min-width:60px;
	text-align:center;
	margin-top:3px;
}

/*-------- 2.14 Autocomplete Address --------*/
.pac-container {
  margin-top: 5px;
  border-top:none!important;
  @include border-radius(4px!important);
  font-family: $font-main;
  color: $body-text-color;
  @include box-shadow (0 12px 35px 2px rgba(0, 0, 0, 0.12));
}
.pac-item-query {
    color: $body-text-color;
}

/*-------- 2.15 OpenStreet Map --------*/
@import "leaflet.scss";

/*============================================================================================*/
/* 3.  COMMON */
/*============================================================================================*/

/*-------- 3.1 Misc --------*/
.closed {
  .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
  }
  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
  }
}

.opened {
  opacity: 1;
  .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
  }
  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
  }
}

.circle-plus {
    height: 14px;
    width: 14px;
	display: block;
	position: relative;
	float: right;
	margin-top: 5px;
}

.circle-plus .horizontal {
    position: absolute;
    background-color: #999;
    width: 14px;
    height: 2px;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: 0;
}
.circle-plus .vertical {
    position: absolute;
    background-color: #999;
    width: 2px;
    height: 14px;
    left: 50%;
    margin-left: -2px;
    top: 50%;
    margin-top: -6px;
}

.form-control {
	height: calc(2.55rem + 2px);
	@include fontSize(14px);
	@include border-radius(3px);
	color: #495057;
	border: 1px solid $border-color-3;
}
.form-group {
	position: relative;
	margin-bottom: 1rem;
}
.bg_color_1 {
	background-color: #fff;
}
.box_1 {
	background-color: $color-4;
	color: #fff;
	padding: 45px;
	opacity: 0;	
	p {opacity: 0.8;}
	h3 {color: #fff;}
}

.animated {
	.box_1 {
		animation: color 0.3s ease-in-out;
		@include animation-delay (0.3s);
		@include animation-fill (forwards);
		opacity: 0;
		@include backface-visibility (hidden);
	}
}

.call_section {
	background-color:$color-1;
	position: relative;
	color: #fff;
	h2 {
		color: #fff;
	}
	.wrapper {
		@include rgba(bg, #000, 0.7);
		position: relative;
		z-index: 22;
	}
	.box_how {
		text-align: center;
		position: relative;
		h3 {
			color: #fff;
			@include fontSize(18px);
		}
		i {
			color: #fff;
			@include fontSize(60px);
			margin-bottom: 10px;
			color: $color-5;
		}
	}
	&.pattern {
		background: $color-1 url(../img/pattern.svg) center center repeat fixed;
		.wrapper {
			@include rgba(bg, #000, 0.6);
		}
	}
	&.image_bg {
		background: $color-1 url(../img/bg_call_section.jpg) center center no-repeat fixed;
   		@include background-size(cover);
		.wrapper {
			@include rgba(bg, #000, 0.8);
		}
	}
}
 #hero-canvas {
 	width: 100%;
 	height: 100%;
 	position: absolute;
 	top: 0;
 	left: 0;
 	z-index: -1;
 }

$hoverDuration: 170ms;
$borderColor: rgba(0,0,0,.1);

.clear{
  &:after,
  &:before{
    content: "";
    display: table;
  }
  &:after{
    clear: both;
  }
}

.pagination__wrapper {
	height: 50px;
	padding-top: 10px;
	margin-bottom: 0;
	position: relative;
	text-align: center;
	width: 100%;
	&:before {
		top: -1px;
	}
	&:after {
		bottom: -1px;
	}
}

@keyframes hoverAnimation{
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}

.pagination {
	display: inline-block;
	list-style: none;
	margin: 0;
	padding: 0;
	@extend .clear;
	li {
		//border-left: 1px solid rgba(0,0,0,.2);
		display: block;
		float: left;
		padding: 5px;
		&:first-child {
			border: none;
		}
	}
	a,
	span {
		background: none;
		border: none;
		border-radius: 50%;
		box-sizing: border-box;
		color: rgba(0, 0, 0, .6);
		display: block;
		font-size: 16px;
		height: 40px;
		line-height: 40px;
		min-width: 40px;
		padding: 0;
	}
	a {
		outline: none;
		position: relative;
		transition: all 170ms linear;
		&:before {
			background: rgba(0, 0, 0, .2);
			border-radius: 50%;
			content: "";
			cursor: pointer;
			height: 0;
			left: 50%;
			opacity: 0;
			position: absolute;
			transform: translate(-50%, -50%);
			transition: all $hoverDuration linear;
			top: 50%;
			width: 0;
		}
		&:hover:not(.active) {
			color: rgba(0, 0, 0, 1);
			&:before {
				animation: hoverAnimation ($hoverDuration * 3) linear forwards;
				height: 40px;
				width: 40px;
			}
		}
		&.active {
			background: $color-1;
			color: rgba(255, 255, 255, 1);
			cursor: default;
		}
	}
	.prev,
	.next {
		font-size: 14px;
	}
}

.box_faq {
	position: relative;
	padding-left: 50px;
	h4 {
		@include fontSize(18px);
	}
	i {
		@include fontSize(36px);
		position: absolute;
		left: 0;
		top: 0;
		color: #999;
	}
}

.custom-select-form {
	.nice-select {
		@include border-radius(3px);
		border: 1px solid $border-color-3;
		height: 45px;
		line-height: 42px;
		&:hover {
			border-color: $border-color-3;
		}
		&:active,
		&:focus {
			border-color: #80bdff;
			outline: 0;
			box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
		}
	}
	select {
		display: none;
	}
}

ul.share-buttons {
	padding: 0;
	list-style: none;
	text-align: center;
	margin: 0 0 25px 0;
	li {
		display: inline-block;
		margin: 0 5px 5px 5px;
		 a {
			background: #fff;
			border: 1px solid $border-color-2;
			@include border-radius(3px);
			font-weight: 500;
			@include fontSize(13px);
			padding: 7px 20px;
			transition: 0.3s;
			display: inline-block;
			line-height: 17px;
			font-weight: 500;
			&:hover {color: #fff;}
			&.fb-share {
				color: #3b5998;
				&:hover {background: #3b5998;color: #fff; border-color:#3b5998;}
			}
			&.gplus-share {
				color: #dd4b39;
				&:hover {background: #dd4b39;color: #fff; border-color:#dd4b39;}
			}
			&.twitter-share {
				color: #1da1f2;
				&:hover {background: #1da1f2;color: #fff; border-color:#1da1f2;}
			}
		}
		i {
			@include fontSize(16px);
			position: relative;
			right: 3px;
			top: 2px;
		}
	}
}

/* Jquery select */
// Default variables
$font_size: 14px !default;
$font_size_small: 12px !default;

$border_radius: 3px !default;
$height: 50px !default;
$height_small: 36px !default;
$dropdown_padding: 15px !default;

$gray_dark: #444 !default;
$gray: #999 !default;
$gray_light: #999 !default;
$gray_lighter: #f6f6f6 !default;
$primary_light: $gray !default;
$arrow_color: $gray_light !default;

// Style the dropdown
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: $border_radius;
  border: none; 
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: $font_size;
  font-weight: normal;
  height: $height;
  line-height: $height - 2;
  outline: none;
  padding-left: $dropdown_padding;
  padding-right: $dropdown_padding + 12;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
  color: $body-text-color;
  padding-top: 2px;
  &:hover {
    border-color: darken($gray_light, 5%);
  }
  &:active, &:focus {
    border-color: $primary_light;
  }
  // Arrow
  &:after { 
    border-bottom: 2px solid $arrow_color;
    border-right: 2px solid $arrow_color;
    content: '';
    display: block; 
    height: 8px;
    margin-top: -5px;
    pointer-events: none;
    position: absolute; 
    right: 20px; 
    top: 50%; 
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 8px;
  }
  
  &.open {
    @extend :active;
    &:after {
      transform: rotate(-135deg);
    }
    .list {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1) translateY(0);
    }
  }
  &.disabled {
    border-color: lighten($gray_light, 2%);
    color: $gray;
    pointer-events: none;
    &:after { 
      border-color: lighten($arrow_color, 20%);
    }
  }
  
  // Modifiers
  &.wide {
    width: 100%;
    .list {
      left: -1px !important;
      right: -1px !important;
    }
  }
  &.right {
    float: right;
    .list {
      left: auto;
      right: 0;
    }
  }
  &.small {
    font-size: $font_size_small;
    height: $height_small;
    line-height: $height_small - 2;
    &:after { 
      height: 4px;
      width: 4px;
    }
    .option {
      line-height: $height_small - 2;
      min-height: $height_small - 2;
    }
  }
  
  // List and options
  .list {
    background-color: #fff;
    border-radius: $border_radius;
    box-shadow: 0 0 0 1px rgba($gray_dark, .11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%; left: 0;
    transform-origin: 50% 0;
    transform: scale(.75) translateY(- $height / 2);
    transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out;
    z-index: 9999;
    &:hover .option:not(:hover) {
      background-color: transparent !important;
    }
	// for overflow fix. from here.
    height: 23vh;
    overflow: auto;
    
    // for custom scrollbar inside select box
    &::-webkit-scrollbar{
      width: 14px;
      height: 18px;
    }
    &::-webkit-scrollbar-thumb {
      height: 6px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 7px;
      background-color: rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent
    }
  }
  .option {
    cursor: pointer;
    font-weight: 400;
    line-height: $height - 12;
    list-style: none;
    min-height: $height - 12;
    outline: none;
    padding-left: $dropdown_padding;
    padding-right: $dropdown_padding + 11;
    text-align: left;
    transition: all 0.2s;
    &:hover, &.focus, &.selected.focus { 
      background-color: $gray_lighter;
    }
    &.selected { 
      font-weight: 500;
    }
    &.disabled {
      background-color: transparent;
      color: $gray;
      cursor: default;
    }
  }
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select {
  .list {
    display: none;
  }
  &.open {
    .list {
      display: block;
    }
  }
}

/* Scroll top button */
#toTop {
	position: fixed;
	right: 25px;
	bottom: 25px;
	z-index: 9999;
	width: 46px;
	height: 46px;
	background-color: rgba(0,0,0,.6);
	opacity: 1;
    border-radius: 50%;
    text-align: center;
    font-size: 21px;
	color: #fff;
	cursor: pointer;
	@include mobile {display: none!important;}
	&:after {
		content: "\e899";
		font-family: "fontello";
		position: relative;
		display: block;
		top: 50%;
		-webkit-transform: translateY(-55%);
		transform: translateY(-55%);
	}
	&:hover {
		background-color: $color-1;
	}
}
/*-------- 3.2 Accordion --------*/
.accordion_2 {
	.card {
		border: 0;
		margin-bottom: 5px;
		@include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));
		.card-header {
			background-color: #fff;
			border-bottom: 1px solid $border-color-2;
			padding: 20px;
		}
	}
	.card-body {
		padding-bottom: 0;
	}
	.card-header h5 {
		@include fontSize(16px);
		a {
			border: 0;
			display: block;
			color: #999;
			i.indicator {
				@include fontSize(18px);
				font-weight: normal;
				float: right;
				color: $color-1;
			}
		}
	}
}
.card {
    border-radius: 0;
	border: 0;
	margin-bottom: 5px;
}

/*-------- 3.3 List --------*/
ul.list_ok {
	list-style: none;
	margin: 0 0 30px 0;
	padding: 0;
	li {
		position: relative;
		padding-left: 25px;
		margin-bottom: 10px;
		&:before {
			font-family: 'ElegantIcons';
			content: "\52";
			position: absolute;
			left: 0;
			top: -5px;
			@include fontSize(18px);
			color: $color-3;
		}
	}
}

ul.bullets {
	line-height: $line-height-large;
	margin: 0;
	padding: 0;
	li {
		position: relative;
		padding-left: 23px;
		&:before {
			font-style: normal;
			font-weight: normal;
			font-family: 'ElegantIcons';
			@include fontSize(14px);
			content: "\e052";
			color: $color-3;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

/*-------- 3.4 Spacing --------*/
.add_bottom_10 {
    margin-bottom: 10px;
}
.add_bottom_15 {
    margin-bottom: 15px;
}
.add_bottom_30 {
    margin-bottom: 30px;
}
.add_bottom_45 {
    margin-bottom: 45px;
}
.add_bottom_60 {
    margin-bottom: 60px;
}
.add_bottom_75 {
    margin-bottom: 75px;
}
.add_top_10 {
    margin-top: 10px;
}
.add_top_15 {
    margin-top: 15px;
}
.add_top_20 {
    margin-top: 20px;
}
.add_top_30 {
    margin-top: 30px;
}
.add_top_60 {
    margin-top: 60px;
}
.more_padding_left {
    padding-left: 40px;
}
.nomargin_top {
    margin-top: 0;
}
.nopadding {
    margin: 0 !important;
    padding: 0 !important;
}
.nomargin {
    margin: 0 !important;
}
.margin_30 {
	padding-top: 30px;
	padding-bottom: 30px;
}
.margin_30_5 {
	padding-top: 30px;
	padding-bottom: 5px;
}
.margin_60 {
	padding-top: 60px;
	padding-bottom: 60px;
	@include mobile {padding-top: 30px; padding-bottom: 30px;}
}
.margin_60_35 {
	padding-top: 60px;
	padding-bottom: 35px;
	@include extra-small-mobile {padding-top: 30px; padding-bottom: 5px;}
}
.margin_80 {
	padding-top: 80px;
	padding-bottom: 80px;
	@include tablet {padding-bottom: 60px; padding-top: 60px;}
	@include extra-small-mobile {padding-top: 30px; padding-bottom: 30px;}
}
.margin_80_55 {
	padding-top: 80px;
	padding-bottom: 55px;
	@include tablet {padding-top: 60px; padding-bottom: 35px;}
	@include extra-small-mobile {padding-top: 45px; padding-bottom: 15px;}
}
.margin_80_0 {
	padding-top: 80px;
	@include tablet {padding-top: 60px;}
	@include extra-small-mobile {padding-top: 30px;}
}
.margin_30_95 {
	padding-top: 30px;
	padding-bottom: 95px;
	@include tablet {padding-top: 15px;padding-bottom: 35px;}
	@include extra-small-mobile {padding-top: 5px; padding-bottom: 5px;}
}
.hidden_tablet{
	@include tablet {display: none!important;}
}
.hidden_mobile {
	@include mobile {display: none!important;}
}

/*-------- 3.5 Block reveal --------*/
.animated .block-reveal {
	height: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}
.block-horizzontal,.block-vertical {
	height: 100%;
	position: absolute;
	width: 100%;
	z-index: 10;
}
.animated {
	.block-horizzontal {
		animation: reveal_left_to_right 1s ease-in-out;
		@include animation-fill (forwards);
		background: $color-2;
	}
	.block-vertical {
		animation: reveal_top_to_bottom 1s ease-in-out;
		@include animation-fill (forwards);
		background: $color-2;
	}
}

.block-reveal img{
	@include transition-default;
	opacity: 0;
}
.animated .block-reveal img {
	animation: color 0.5s ease-in-out;
    @include animation-delay (0.5s);
    @include animation-fill (forwards);
    opacity: 0;
	@include backface-visibility (hidden);
}

@keyframes reveal_left_to_right {
	0% {
		@include transform (translateX(-100%));
	}
	50% {
		@include transform (translateX(0));
	}
	100% {
		@include transform (translateX(100%));
	}
}

@keyframes reveal_top_to_bottom {
	0% {
		@include transform (translateY(-100%));
	}
	50% {
		@include transform (translateY(0));
	}
	100% {
		@include transform (translateY(100%));
	}
}

@keyframes color {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/*-------- 3.6 Cookie bar --------*/
#cookie-bar {
	background: rgba(0, 0, 0, 0.8);
	height: auto;
	line-height: 24px;
	color: #eeeeee;
	text-align: center;
	padding: 15px 0;
	font-weight: 400;
	@include fontSize(13px);
	font-weight: 500;
	p {
		margin: 0;
		padding: 0;
	}
	a {
		color: #fff;
		display: inline-block;
		@include border-radius(3px);
		text-decoration: none;
		padding: 2px 10px;
		margin-left: 8px;
		@include mobile {
			display: block;
			max-width: 150px;
			margin: auto;
			margin-bottom: 5px;
		}
	}
	.cb-enable {
		background: $color-3;
		&:hover {
			background: #fff;
			color: $color-2;
		}
	}
	.cb-disable {
		background: #990000;
		&:hover {
			background: #bb0000;
		}
	}
	.cb-policy {
		background: $color-1;
		&:hover {
			background: #fff;
			color: $color-2;
		}
	}
	&.fixed {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 999999;
		&.bottom {
			bottom: 0;
			top: auto;
		}
	}
	@include mobile {
		padding: 5px 0;
	}
}

/*-------- 3.7 Sing In Modal --------*/
#sign-in-dialog {
  background: #fff;
  padding: 30px;
  padding-top: 0;
  text-align: left;
  max-width: 400px;
  margin: 40px auto;
  position: relative;
  box-sizing: border-box;
  @include border-radius(4px);
}

/* Popup close button*/
#sign-in-dialog .mfp-close,
#lang-dialog .mfp-close {
  color: #666;
  background-color: #e4e4e4;
  @include border-radius(50%);
  top: 12px;
  right: 20px;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
#sign-in-dialog .mfp-close:hover,
#lang-dialog .mfp-close:hover {
  color: #fff;
  background-color: #66676b;
}

#sign-in-dialog .mfp-close:before{
  font-size: 24px;
  font-family: 'ElegantIcons';
  content: "\4d";
}
.small-dialog-header {
  font-size: 18px;
  width: calc(100% + 60px);
  position: relative;
  left: -30px;
  top: 0;
  @include border-radius(4px 4px);
  display: inline-block;
  background-color: #f6f6f6;
  padding: 18px 20px 15px 20px;
  margin-bottom: 30px;
  h3 {
	  padding: 0;
	  margin: 0;
	  @include fontSize(18px);
	  font-weight: 500;
	}
}
#sign-in-dialog .form-group {
  position: relative;
}
#sign-in-dialog .form-group input.form-control {
  padding-left: 40px;
}
#sign-in-dialog .form-group i {
  @include fontSize(21px);
  position: absolute;
  left: 12px;
  top: 34px;
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400!important;
}
#sign-in-dialog .form-group i.icon_lock_alt {
  top: 36px;
}

.my-toggle {
  background: transparent;
  border: 0;
  @include border-radius(3px);
  color: #888;
  cursor: pointer;
  font-size: 10px;
  @include fontSize(10px);
  font-weight: bold;
  margin-right: 5px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  text-transform: uppercase;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
	&:hover,&:focus {
	  background-color: #eee;
	  color: #555;
	  outline: transparent;
	}
}

.hideShowPassword-wrapper{
  width: 100%!important;
}

.checkboxes label{
  color: #999;
  margin-top: 5px;
}
a#forgot{
  color: #999;
  font-weight: 500;
	@include fontSize(13px);
}

#forgot_pw {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  min-height: 430px;
  display: none;
  padding: 25px;
  @include mobile {padding: 0;}
  label {
	  font-weight: 500;
	}
}

.sign-in-wrapper {
  position: relative;
  height: 100%;
}

a.social_bt {
  @include border-radius(3px);
  text-align: center;
  color: #fff;
  min-width: 200px;
  margin-bottom: 15px;
  display: block;
  padding: 12px;
  line-height: 1;
  position: relative;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
	  -webkit-filter: brightness(115%);
	  filter: brightness(115%);
	}
}
a.social_bt.facebook:before,
a.social_bt.google:before,
a.social_bt.linkedin:before {
	font-family: 'ElegantIcons';
	position: absolute;
	left: 12px;
	top: 10px;
	font-size: 1rem;
	color: #fff;
}

a.social_bt.facebook {
	background-color: #3B5998;
	&:before {
		content: "\e093";
	}
}

a.social_bt.google {
	background-color: #DC4E41;
	&:before {
		content: "\e096";
		top: 12px;
	}
}

.divider {
	text-align: center;
	height: 1px;
	margin: 30px 0 20px 0;
	background-color: #e1e8ed;
	span {
		position: relative;
		top: -20px;
		background-color: #fff;
		display: inline-block;
		padding: 10px;
		font-style: italic;
	}
}

/* Magnific popup */
.mfp-bottom-bar {
    padding: 0 10px!important;
}
.mfp-counter {
    right: 10px!important;
	color: #fff!important;
}
.mfp-bg {
    opacity: 0.8!important;
}

/*-------- 3.8 Datepicker --------*/
.applyBtn.btn.btn-sm.btn-primary {
	background-color: $color-1;
	border: 0;
}

.drp-selected {
	display: none!important;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $color-2!important;
}

/*-------- Styles Switcher --------*/
#style-switcher {
	background: #fff;
	width: 205px;
	position: fixed;
	top: 205px;
	z-index: 99;
	left: -205px;
	border-radius: 0 0 4px 0;
	-webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, .175);
	box-shadow: 0 6px 15px rgba(0, 0, 0, .175);
	h3 {
		color: #fff;
		font-size: 13px;
		margin: 10px 0;
		font-weight: 500;
		text-transform: none;
		letter-spacing: 0;
	}
	ul {
		list-style: none;
		padding: 0;
		margin-bottom: 22px;
	}
	div {
		padding: 6px 18px;
	}
	h6 {
		background: #f8f8f8;
		color: #333;
		font-size: 14px;
		line-height: 46px;
		padding: 0 0 0 20px;
		margin-top: 0;
		font-weight: 500;
		a {
			background-color: #f8f8f8;
			display: block;
			height: 46px;
			position: absolute;
			right: -46px;
			top: 0;
			width: 46px;
			border-radius: 0 4px 4px 0;
			font-size: 22px;
			color: #fff;
			text-align: center;
			i {
				position: relative;
				top: 4px;
				color: #333;
			}
		}
	}
}

	.colors {
		list-style: none;
		margin: 0 0px 10px 0;
		overflow: hidden;
	}
	
	.colors li {
		float: left;
		margin: 4px 2px 0 2px;
	}
	
	.colors li a {
		display: block;
		width: 17px;
		height: 17px;
		cursor: pointer;
	}
	
	.default {
		background: $color-1;
	}
	
	.aqua {
		background: #1cbbb4;
	}
	
	.green_switcher {
		background: #82ca9c;
	}
	
	.orange {
		background: #f7941d;
	}

	.beige {
		background: #bea882;
	}

	.gray {
		background: #555555;
	}

	.green-2 {
		background: #18a84c;
	}

	.navy {
		background: #4a5d7d;
	}

	.peach {
		background: #faaa5d;
	}

	.purple {
		background: #6449e7;
	}

	.red {
		background: #f0151f;
	}

	.violet {
		background: #990099;
	}
	
	.colors li a.active {
		box-shadow: 0 0 0 2px #fff inset;
	}
	
	.colors li a {
		border-radius: 3px;
	}
	
	@media only screen and (max-width: 1024px) {
		#style-switcher {
			display: none;
		}
	}