@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

@import "~bootstrap/scss/bootstrap";
@import '~jquery.mmenu/dist/jquery.mmenu.all';
@import '~hamburgers/dist/hamburgers';
@import '~magnific-popup/dist/magnific-popup';
@import '~slider-pro/dist/css/slider-pro';
@import '~owl.carousel/dist/assets/owl.carousel';
@import '~owl.carousel/dist/assets/owl.theme.default';
// Ensure text remains visible during webfont load by including "font-display: swap;" in your "@font-face" style
@import '../css/icon_fonts/css/all_icons.min.css';
@import '../css/icon_fonts_ct/css_ct/icon_set_1.css';
@import '../css/icon_fonts_ct/css_ct/icon_set_2.css';
@import '~flag-icon-css/sass/flag-icons';
@import "style";
@import "color-orange";
@import 'custom';