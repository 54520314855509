@font-face {
  font-family: 'icon_set_1';
  src: url('../font_ct/icon_set_1.eot?55361665');
  src: url('../font_ct/icon_set_1.eot?55361665#iefix') format('embedded-opentype'),
       url('../font_ct/icon_set_1.woff?55361665') format('woff'),
       url('../font_ct/icon_set_1.ttf?55361665') format('truetype'),
       url('../font_ct/icon_set_1.svg?55361665#icon_set_1') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icon_set_1';
    src: url('../font_ct/icon_set_1.svg?55361665#icon_set_1') format('svg');
  }
}
*/
 
 [class^="icon_set_1_"]:before, [class*="icon_set_1_"]:before {
  font-family: "icon_set_1";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
     
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon_set_1_icon-1:before { content: '\21'; } /* '!' */
.icon_set_1_icon-2:before { content: '\22'; } /* '&quot;' */
.icon_set_1_icon-3:before { content: '\23'; } /* '#' */
.icon_set_1_icon-4:before { content: '\24'; } /* '$' */
.icon_set_1_icon-5:before { content: '\25'; } /* '%' */
.icon_set_1_icon-6:before { content: '\26'; } /* '&amp;' */
.icon_set_1_icon-7:before { content: '\27'; } /* ''' */
.icon_set_1_icon-8:before { content: '\28'; } /* '(' */
.icon_set_1_icon-9:before { content: '\29'; } /* ')' */
.icon_set_1_icon-10:before { content: '\2a'; } /* '*' */
.icon_set_1_icon-11:before { content: '\2b'; } /* '+' */
.icon_set_1_icon-12:before { content: '\2c'; } /* ',' */
.icon_set_1_icon-13:before { content: '\2d'; } /* '-' */
.icon_set_1_icon-14:before { content: '\2e'; } /* '.' */
.icon_set_1_icon-15:before { content: '\2f'; } /* '/' */
.icon_set_1_icon-16:before { content: '\30'; } /* '0' */
.icon_set_1_icon-17:before { content: '\31'; } /* '1' */
.icon_set_1_icon-18:before { content: '\32'; } /* '2' */
.icon_set_1_icon-19:before { content: '\33'; } /* '3' */
.icon_set_1_icon-20:before { content: '\34'; } /* '4' */
.icon_set_1_icon-21:before { content: '\3a'; } /* '5' */
.icon_set_1_icon-22:before { content: '\35'; } /* '5' */
.icon_set_1_icon-23:before { content: '\36'; } /* '6' */
.icon_set_1_icon-24:before { content: '\37'; } /* '7' */
.icon_set_1_icon-25:before { content: '\38'; } /* '8' */
.icon_set_1_icon-26:before { content: '\39'; } /* '9' */
.icon_set_1_icon-27:before { content: '\3a'; } /* ':' */
.icon_set_1_icon-27:before { content: '\3b'; } /* ';' */
.icon_set_1_icon-28:before { content: '\3c'; } /* '&lt;' */
.icon_set_1_icon-29:before { content: '\3d'; } /* '=' */
.icon_set_1_icon-30:before { content: '\3e'; } /* '&gt;' */
.icon_set_1_icon-31:before { content: '\3f'; } /* '?' */
.icon_set_1_icon-32:before { content: '\40'; } /* '@' */
.icon_set_1_icon-33:before { content: '\41'; } /* 'A' */
.icon_set_1_icon-34:before { content: '\42'; } /* 'B' */
.icon_set_1_icon-35:before { content: '\43'; } /* 'C' */
.icon_set_1_icon-36:before { content: '\44'; } /* 'D' */
.icon_set_1_icon-37:before { content: '\45'; } /* 'E' */
.icon_set_1_icon-38:before { content: '\46'; } /* 'F' */
.icon_set_1_icon-39:before { content: '\47'; } /* 'G' */
.icon_set_1_icon-40:before { content: '\48'; } /* 'H' */
.icon_set_1_icon-41:before { content: '\49'; } /* 'I' */
.icon_set_1_icon-42:before { content: '\4a'; } /* 'J' */
.icon_set_1_icon-43:before { content: '\4b'; } /* 'K' */
.icon_set_1_icon-44:before { content: '\4c'; } /* 'L' */
.icon_set_1_icon-45:before { content: '\4d'; } /* 'M' */
.icon_set_1_icon-46:before { content: '\4e'; } /* 'N' */
.icon_set_1_icon-47:before { content: '\4f'; } /* 'O' */
.icon_set_1_icon-48:before { content: '\50'; } /* 'P' */
.icon_set_1_icon-49:before { content: '\51'; } /* 'Q' */
.icon_set_1_icon-50:before { content: '\52'; } /* 'R' */
.icon_set_1_icon-51:before { content: '\53'; } /* 'S' */
.icon_set_1_icon-52:before { content: '\54'; } /* 'T' */
.icon_set_1_icon-53:before { content: '\55'; } /* 'U' */
.icon_set_1_icon-54:before { content: '\56'; } /* 'V' */
.icon_set_1_icon-55:before { content: '\57'; } /* 'W' */
.icon_set_1_icon-56:before { content: '\58'; } /* 'X' */
.icon_set_1_icon-57:before { content: '\59'; } /* 'Y' */
.icon_set_1_icon-58:before { content: '\5a'; } /* 'Z' */
.icon_set_1_icon-59:before { content: '\5b'; } /* '[' */
.icon_set_1_icon-60:before { content: '\5c'; } /* '\' */
.icon_set_1_icon-61:before { content: '\5d'; } /* ']' */
.icon_set_1_icon-62:before { content: '\5e'; } /* '^' */
.icon_set_1_icon-63:before { content: '\5f'; } /* '_' */
.icon_set_1_icon-64:before { content: '\60'; } /* '`' */
.icon_set_1_icon-65:before { content: '\61'; } /* 'a' */
.icon_set_1_icon-66:before { content: '\62'; } /* 'b' */
.icon_set_1_icon-67:before { content: '\63'; } /* 'c' */
.icon_set_1_icon-68:before { content: '\64'; } /* 'd' */
.icon_set_1_icon-69:before { content: '\65'; } /* 'e' */
.icon_set_1_icon-70:before { content: '\66'; } /* 'f' */
.icon_set_1_icon-71:before { content: '\67'; } /* 'g' */
.icon_set_1_icon-72:before { content: '\68'; } /* 'h' */
.icon_set_1_icon-73:before { content: '\69'; } /* 'i' */
.icon_set_1_icon-74:before { content: '\6a'; } /* 'j' */
.icon_set_1_icon-75:before { content: '\6b'; } /* 'k' */
.icon_set_1_icon-76:before { content: '\6c'; } /* 'l' */
.icon_set_1_icon-77:before { content: '\6d'; } /* 'm' */
.icon_set_1_icon-78:before { content: '\6e'; } /* 'n' */
.icon_set_1_icon-79:before { content: '\6f'; } /* 'o' */
.icon_set_1_icon-80:before { content: '\70'; } /* 'p' */
.icon_set_1_icon-81:before { content: '\71'; } /* 'q' */
.icon_set_1_icon-82:before { content: '\72'; } /* 'r' */
.icon_set_1_icon-83:before { content: '\73'; } /* 's' */
.icon_set_1_icon-84:before { content: '\74'; } /* 't' */
.icon_set_1_icon-85:before { content: '\75'; } /* 'u' */
.icon_set_1_icon-86:before { content: '\76'; } /* 'v' */
.icon_set_1_icon-87:before { content: '\77'; } /* 'w' */
.icon_set_1_icon-88:before { content: '\78'; } /* 'x' */
.icon_set_1_icon-89:before { content: '\79'; } /* 'y' */
.icon_set_1_icon-90:before { content: '\7a'; } /* 'z' */
.icon_set_1_icon-91:before { content: '\7b'; } /* '{' */
.icon_set_1_icon-92:before { content: '\7c'; } /* '|' */
.icon_set_1_icon-93:before { content: '\7d'; } /* '}' */
.icon_set_1_icon-94:before { content: '\7e'; } /* '~' */
.icon_set_1_icon-95:before { content: '\e800'; } /* '' */
.icon_set_1_icon-96:before { content: '\e801'; } /* '' */
.icon_set_1_icon-97:before { content: '\e802'; } /* '' */
.icon_set_1_icon-98:before { content: '\e803'; } /* '' */
.icon_set_1_icon-99:before { content: '\e804'; } /* '' */
.icon_set_1_icon-100:before { content: '\e805'; } /* '' */