@import 'variables';
@import 'mixin';
@import 'layout';

/*-------- COLOR AQUA--------*/
$color-1: #f7941d;

/* Main navigation */
header.sticky .main-menu>ul>li span {
		>a {
			color: #444;
		}
		&:hover>a {
			opacity: 1;
			color: $color-1;
		}
	}
.main-menu ul ul li {
		&:hover>a {
			background-color: #f9f9f9;
			color: $color-1;
			padding-left: 18px;
		}
	}

/* Submenu 3rd level */
.main-menu ul ul li span a {
	&:hover {
		background-color: #f9f9f9;
		color: $color-1;
	}
}

header.sticky .main-menu>ul>li span,  header.header_in .main-menu>ul>li span{
		>a {
			color: #444;
		}
		&:hover>a {
			opacity: 1;
			color: $color-1;
		}
	}
/*-------- 1.1 Typography --------*/
.main_title_2 {
	span {
		em {
			background-color:$color-1; 
		}
	}
}
.main_title_3 {
	span {
		background-color:$color-1;
	}
}

/*General links color*/
a {	
	color: $color-1;
	&:hover, &:focus {color: #111;text-decoration: none; outline:none;}
}

/*-------- 1.2 Buttons --------*/
a.btn_1,
.btn_1 {
    background: $color-1;
	&.outline {
		border: 2px solid $color-1;
		color: $color-1;
	}
	&.full-width{
		&.purchase{
			background-color: $color-1;
			&:hover {
				background-color: $color-5;
				color: #444!important;
			}
		}
	}
}
a.btn_map {
	&:hover{
		color: $color-1;
	}
}
a.btn_map_in,
.btn_map_in {
    background: $color-1;
}

a.btn_add,
.btn_add {
	background: $color-1;
}

a.btn_filt, a.btn_filt_map {
	&:hover{
		color: $color-1;
	}
}

a.btn_2, .btn_2{
	background: $color-1;
	&:hover{
		background: $color-5;
		color:#444;
	}
}

/*-------- 1.3 Structure --------*/
.hero_in {
	a.btn_photos {
		&:hover{
			color: $color-1;
		}
	}
}

/* Newsletter footer*/
#newsletter {
	input[type='submit'] {
		background-color: $color-1;
	}
}

.follow_us {
	ul {
		li {
			a i {
				color: $body-text-color;
				opacity: 0.6;
				&:hover {
					opacity: 1;
					color: $color-1;
				}
			}
		}
	}
}

/* Footer */
footer {
	ul {
		li {
			a {
				&:hover {
					color: $color-1;
					opacity: 1;
				}
			}
		}
		&.contacts li {
			i {
				color: $color-1;
			}
		}
		&.links li a {
			&:hover {
				&:after {
					opacity: 1;
					color: $color-1;
				}
			}
		}
	}
}

/*============================================================================================*/
/* 2.  CONTENT */
/*============================================================================================*/

/* Home single hero */
.hero_single {
	.wrapper {
		@include rgba(bg, #000, 0.8);
	}
	&.version_4 {
		background: $color-1 url(../img/pattern_1.svg) center bottom repeat-x;
		.wrapper {
			input[type='submit'] {
				color: #222;
				background-color: $color-5;
				&:hover {
					background-color: $color-2;
				}
			}
		}
	}
	&.version_5 {
		background: $color-1 url(../img/pattern_2.svg) center center repeat fixed;
		.wrapper {
			@include background-gradient(rgba(0, 0, 0, 0.6), transparent, 'vertical');
			input[type='submit'] {
				color: #222;
				background-color: $color-5;
				&:hover {
					background-color: $color-2;
					color: #fff;
				}
			}
		}
	}
}

a.address {
	&:hover {
		color: $color-1;
	}
}

a.grid_item {
	.info {
		small {
			background-color: $color-1;
		}
	}
}

.call_section {
	background-color:$color-1;
	position: relative;
	color: #fff;
	.wrapper {
		@include rgba(bg, #000, 0.5);
	}
	&.pattern {
		background: $color-1 url(../img/pattern.svg) center center repeat fixed;
		.wrapper {
			@include rgba(bg, #000, 0.6);
		}
	}
	&.image_bg {
		background: $color-1 url(../img/bg_call_section.jpg) center center no-repeat fixed;
   		@include background-size(cover);
		.wrapper {
			@include rgba(bg, #000, 0.8);
		}
	}
}

#custom-search-input {
	input[type='submit'] {
		background-color: $color-1;
	}
}

.custom-search-input-2 {
	input[type='submit'] {
		color: #222;
		background-color: $color-5;
		&:hover {
			background-color: $color-2;
			color: #fff;
		}
	}
	&.inner {
		input[type='submit'] {
			background: $color-5 url(../img/search.svg) no-repeat center center;
			&:hover {
				background-color: $color-2;
			}
			@include extra-small-mobile {
				background: $color-1 url(../img/search.svg) no-repeat center center;
			}
		}
	}
}

.search_mob_wp {
	input[type='submit'] {
		background-color: $color-5;
		color: #222;
		&:hover {
			background-color: $color-2;
			color: #fff;
		}
	}
}

.distance {
	span {
		color:$color-1;
	}
}
.rangeslider__fill {
    background: $color-1 !important;
}
.rangeslider__handle {
    border: 2px solid $color-1 !important;
}

.container_check {
	input {
		&:checked~.checkmark {
			background-color: $color-1;
		}
	}
}

.container_radio {
	.checkmark {
		&:after {
			background: $color-1;
		}
	}
}

#search_mobile {
	a.side_panel {
		color: #999;
		&:hover {color:$color-1;}
	}
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: $color-1 !important;
}

a.box_news {
	&:hover {
		h4 {
			color: $color-1;
		}
	}
}


#results {
	background: $color-1;
}
#results_map_view {
	color: #fff;
	background: $color-1;
}

.secondary_nav {
    background: $color-1;
}

.sub_header_in {
	background-color: $color-1;
}

.step .nav-tabs .nav-link.active,
.step .nav-tabs .nav-item.show .nav-link {
  border-bottom: 2px solid $color-1;
  color: $color-1;
}

.payments {
	ul {
		li {
			a.info {
				color: #444;
				&:hover {
					color: $color-1;
				}
			}
		}
	}
}

.box_list {
	a.wish_bt {
		&.liked:after{
			color: $color-1;
		}
		&:hover.liked:after{
			color: $color-1;
		}
	}
}

.layout_view {
	a {
		&:hover {
			color: $color-1;
		}
	}
}

.switch-field {
	label {
		&:hover {
			color: $color-1;
		}
	}
}

a.btn_infobox_detail:before{
	&:hover {
	  color: $color-1;
	}
}

.btn_infobox_get_directions, a.btn_infobox_phone{
	color: $color-1;
}

.contact_info{
	ul {
		li {
			h4 {
				color: $color-1;
			}
		}
	}
}

.form_title {
	h3 {
		strong {
			background-color: $color-1;
		}
	}
}

.grid ul {
	li {
		figcaption {
			a {
				&:hover,
				&:focus {
					color: $color-1;
				}
			}
		}
	}
}

ul#cat_nav {
	li {
		a {
			&:hover,
			&#active,
			&.active {
				color: $color-1;
			}
		}
	}
}

.list_articles {
	ul {
		li {
			a {
				&:hover {
					color: $color-1;
				}
			}
		}
	}
}

#toTop {
	&:hover {
		background-color: $color-1;
	}
}

.accordion_2 {
	.card-header h5 {
		a {
			i.indicator {
				color: $color-1;
			}
		}
	}
}

#cookie-bar {
	.cb-policy {
		background: $color-1;
	}
}

.applyBtn.btn.btn-sm.btn-primary {
	background-color: $color-1;
}

.box_contacts {
	i {
		@include fontSize(46px);
		color: $color-1;
	}
	a {
		color: $body-text-color;
		&:hover {
			color: $color-1;
			text-decoration: underline;
		}
	}
}

.animated {
	.block-horizzontal {
		background: $color-1;
	}
	.block-vertical {
		background: $color-1;
	}
}

#carousel {
	.item {
		.title {
			h4 {
				@include rgba(bg,$color-1,1);
			}
		}
	}
}

#error_page {
	background: $color-1 url(../img/pattern_1.svg) center bottom repeat-x;
	@include mobile {background-size: auto 300px;}
	.wrapper {
		@include background-gradient(rgba(0, 0, 0, 0.6), transparent, 'vertical');
	}
}

.search_bar_error {
	input[type='submit'] {
		background-color: $color-5;
		&:hover {
			background-color: $color-2;
			color: #fff;
		}
	}
}

/*============================================================================================*/
/* MAP CLUSTERER */
/*============================================================================================*/
.cluster-visible {
	background-color: $color-1!important;
	&:before {
		border: 7px solid $color-1!important;
		box-shadow: inset 0 0 0 4px $color-1!important;
	}
}
/*-------- Leaflet cluster --------*/
.marker-cluster div {
	background-color: $color-1;
}
.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
	background-color: $color-1;
}
.marker-cluster div:before {
	border: 7px solid $color-1;
	box-shadow: inset 0 0 0 4px $color-1;
}
/*============================================================================================*/
/* BLOG */
/*============================================================================================*/
article.blog {
    .post_info {
        h2 {
            a {
                &:hover {color: $color-1;}
            }
        }
    }
}

.comments-list {
	h3 {
		a {
			&:hover {
				color: $color-1;
			}
		}
	}
}

.widget {
	ul.cats {
		a {
			&:hover {
				color: $color-1;
			}
		}
	}
}
.tags a {
	&:hover {
		background-color: $color-1;
	}
}

.postmeta {
	ul {
		li {
			a {
				&:hover {
					color: $color-1;
				}
			}
		}
	}
}

.search_blog .form-group input[type="submit"] {
    background-color: $color-1;
    color: #fff;
}

.pagination a.active {
    background: $color-1;
    color: white;
}
/*============================================================================================*/
/* PRICING TABLES */
/*============================================================================================*/

.pricing-switcher {
	.fieldset {
		background-color: $color-1;
	}
	.switch {
		background-color: darken($color-1, 10%);
	}
}

.pricing-wrapper {
	@include MQ(M) {
		>li {
			&::before {
				background-color: lighten($color-1, 60%);
			}
		}
	}
}
