@charset "UTF-8";

 @font-face {
  font-family: 'icon_set_2';
  src: url('../font_ct/icon_set_2.eot?40207016');
  src: url('../font_ct/icon_set_2.eot?40207016#iefix') format('embedded-opentype'),
       url('../font_ct/icon_set_2.woff?40207016') format('woff'),
       url('../font_ct/icon_set_2.ttf?40207016') format('truetype'),
       url('../font_ct/icon_set_2.svg?40207016#icon_set_2') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icon_set_2';
    src: url('../font_ct/icon_set_2.svg?40207016#icon_set_2') format('svg');
  }
}
*/
 
 [class^="icon_set_2_"]:before, [class*=" icon_set_2_"]:before {
  font-family: "icon_set_2";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon_set_2_icon-102:before { content: '󨀃'; } /* '\e8003' */
.icon_set_2_icon-103:before { content: '󨀓'; } /* '\e8013' */
.icon_set_2_icon-105:before { content: '󨀳'; } /* '\e8033' */
.icon_set_2_icon-106:before { content: '󨁃'; } /* '\e8043' */
.icon_set_2_icon-107:before { content: '󨁓'; } /* '\e8053' */
.icon_set_2_icon-108:before { content: '󨁣'; } /* '\e8063' */
.icon_set_2_icon-109:before { content: '󨁳'; } /* '\e8073' */
.icon_set_2_icon-110:before { content: '󨂃'; } /* '\e8083' */
.icon_set_2_icon-111:before { content: '󨂓'; } /* '\e8093' */
.icon_set_2_icon-112:before { content: '󨂣'; } /* '\e80a3' */
.icon_set_2_icon-104:before { content: '󨂳'; } /* '\e80b3' */
.icon_set_2_icon-114:before { content: '󨃃'; } /* '\e80c3' */
.icon_set_2_icon-115:before { content: '󨃓'; } /* '\e80d3' */
.icon_set_2_icon-116:before { content: '󨃣'; } /* '\e80e3' */
.icon_set_2_icon-117:before { content: '󨃳'; } /* '\e80f3' */
.icon_set_2_icon-118:before { content: '󨄃'; } /* '\e8103' */