// breakpoints 
$S:     480px;   
$M:     769px;     
$L:     1170px;  
$XL:    1500px;   

// media queries
@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (min-width: $S) { @content; } 
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; } 
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; } 
  }
  @else if $canvas == XL {
   @media only screen and (min-width: $XL) { @content; } 
  }
}

@mixin column($percentage, $float-direction:left) {
  width: 100% * $percentage;
  float: $float-direction;
}

