/* PARTIALS > MENU > LOGO */
@media (max-width: 991px) {
  #logo  {
    top: 25%;
    img {
      width: 200px;
      height: auto;
      margin: 0;
    }
  }
}

/* PARTIALS > MENU > MENU MAIN */
header.sticky .main-menu>ul>li span,  header.header_in .main-menu>ul>li span{
  >a {
    color: $color-4;
  }
}

.scrollable-menu {
  height: auto;
  max-height: 370px;
  overflow-x: hidden;
}

/* PARTIALS > MENU > MENU TOP */
ul#top_menu li {
  a {
    color: $body-color;
  }
  strong {
    @include fontSize(11px);
    width: 15px;
    height: 15px;
    display: block;
    background-color: $color-1;
    color: $body-color;
    text-align: center;
    position: absolute;
    bottom: -5px;
    right: -8px;
    line-height: 15px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
}

/* PARTIALS > MENU > MENU MOBILE BTN */
.hamburger {
  padding: 0;

  .hamburger-box {
    width: 30px;
  }

  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    background-color: $body-color;
  }
}

/* PARTIALS > PAGINATION */
.page-item.active .page-link {
  background-color: $color-1;
  border-color: $color-1;
}

.pagination__wrapper {
  height: 100%;
}

/* PARTIALS > FOOTER */
footer {
  background: $heading-color url('//d3w1mib0z9ze5m.cloudfront.net/pattern/dark-pattern.png') repeat 0 0;

  h3, ul li, ul li a, #newsletter label, #additional_links li a, #additional_links li span, .follow_us ul li a i  {
    color: $body-color;
  }

  #newsletter input[type=email] {
    background-color: $body-color;
  }
}

.follow_us h5 {
  margin-top: 0;
}

/* PARTIALS > COOKIES */
.cc-revoke, .cc-window {
  font-family: $font-main;
}

/* PARTIALS > BANNER */

/*Banners*/
.banner {
  position: relative;
  background: $body-color url(//d3w1mib0z9ze5m.cloudfront.net/template/banner_bg_colored.png) no-repeat center bottom;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 60px;
}

.banner:after,
.banner:before {
  position: absolute;
  content: "";
  z-index: 1;
  height: 18px;
  width: 50%;
  top: 100%;
}

.banner:after {
  left: 0;
  background: url(//d3w1mib0z9ze5m.cloudfront.net/template/shadow-bg.png) no-repeat top left;
}

.banner:before {
  right: 0;
  background: url(//d3w1mib0z9ze5m.cloudfront.net/template/shadow-bg.png) no-repeat top right;
}

.banner a {
  position: absolute;
}

.banner h4 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 32px;
  margin: 0;
  padding: 0;
  color: $color-1;
  font-family: "Gochi Hand", $font-main;
}

.banner h4 span {
  text-transform: none;
  color: $body-text-color;
}

.banner p {
  margin: 0;
  padding: 0;
}

.banner.colored {
  background: $color-1 url(//d3w1mib0z9ze5m.cloudfront.net/template/banner_bg_colored.png) no-repeat center bottom;
  color: $body-color;
}

.banner.colored h4 {
  color: $body-color;
}

.banner.colored h4 span {
  color: #FFC;
}

.banner.colored a.btn_1.white {
  background: $body-color;
  color: $color-1;
}

.banner.colored a.btn_1.white:hover {
  color: $body-color;
}

/* PARTIALS > ACCORDION CARD */
.accordion_2 .card-body {
  padding-bottom: 30px;
}

/* HOME > PARTIALS > HEADER IMAGE */
.hero_single .wrapper h1 {
  color: $body-color;
  margin: 0;
  font-weight: 700;
  @include fontSize(40px);
  text-transform: initial;
}

.custom-search-input-2 {
	.nice-select {
    height: 50px;
    line-height: 48px;
    .current {
      font-weight: 500;
      color: $heading-color;
    }
  }
}

.hero_single.version_5 .wrapper .nice-select {
  margin-bottom: 10px;
}

.call_section {
	&.image_bg {
		.wrapper {
			@include rgba(bg, #000, 0.3);
      h3 {
        color: $body-color;
      }
		}
	}
}

.hero_single.version_5 {
  height: 100vh;
  background: $color-1 url(//d3w1mib0z9ze5m.cloudfront.net/header/home-solocampamentos.jpg) center center repeat fixed; // 1400*950
  @include background-size(cover); 
}

.hero_single.version_5 .wrapper {
  @include background-gradient(rgba(0, 0, 0, 0.5), transparent, 'vertical');
}

#home_image_bg.call_section.image_bg {
  background: $color-1 url(//d3w1mib0z9ze5m.cloudfront.net/header/image-bg-mcfc.jpg) center center no-repeat fixed;
  @include background-size(cover); 
}

#listing_summer_image_bg.call_section.image_bg {
  background: $color-1 url(//d3w1mib0z9ze5m.cloudfront.net/header/image-bg-summer.jpg) center center no-repeat fixed;
  @include background-size(cover); 
}

.hero_single.version_5 .wrapper input[type=submit] {
  color: $color-4;
  background-color: $color-1;
  &:hover {
    background-color: $color-5;
    color: #222!important;
    opacity: 1!important;
  }
}

.hero_in.hotels_detail {
  height: 350px;
  background-color: rgba(0, 0, 0, 0.4);
}

.hero_single.version_5 .wrapper {
  p {
    @include fontSize(12px);
    font-weight: 700;
    text-align: center;

    a {
      color: $body-color;

      &:hover {
        border-bottom: 1px solid $body-color;
      }
    }
  }
}

.main_categories {
  margin-top: -80px;
  ul li a p {
    font-size: 1rem;
    color: $heading-color;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 991px) {
	.main_categories ul li a p {
      font-size: 0.6rem;
    }
}

/* HOME > PARTIALS > IMAGE BG */
.home_image_bg {
  text-align: center;
  .video {
    color: $body-color;
    &:hover {
      color: $color-5;
    }
    &:first-child {
      @include fontSize(60px);
    }
    p {
      @include fontSize(30px);
    }
  }
}

/* HOME > PARTIALS > BANNER */

.banner_2 {
  background: url(//d3w1mib0z9ze5m.cloudfront.net/header/banner_bg_desktop.jpg) center center no-repeat;
  @include background-size(cover); 
  width: 100%;
  height: 400px;
  margin-bottom: 25px;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
}
.banner_2 .wrapper {
  color: $body-color;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 60px;
}
@media (max-width: 767px) {
  .banner_2 .wrapper {
    padding: 30px;
  }
}
.banner_2 .wrapper h3 {
  color: $body-color;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 700;
}
.banner_2 .wrapper p {
  font-size: 24px;
  font-weight: 300;
}

.opacity-mask {
  width: 100%;
  min-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  display: table;
}

/* HOME > PARTIALS > BOX GROUP */
a.boxed_list {

	h4 {
    margin-top: 20px;
    margin-bottom: 10px;
	}
}

/* HOME > PARTIALS > PARTNER */
.partner {
  margin: 10px;
}

/* HOME > PARTIALS > PARAMETER */
a.box_cat_home {
  h3 {
    @include fontSize(12px);
  }
}

/* PARTIALS > MAIN TITLE CENTER*/
.main_title_2 h1, .main_title_2 h2, .main_title_2 h3, .main_title_2 h4, .main_title_2 h5 {
  @include fontSize(32px);
  font-weight: 700;
  margin: 25px 0 10px 0;
}

.main_title_2 p {
  @include fontSize(15px);
  font-weight: 300;
  line-height: 1.4;
}

/* LISTING > PARTIALS > FILTERS > SITEMAP */
.list_articles ul li {
  width: 20%;
}

/* LISTING > PARTIALS > GRID */

.strip {
  figure {
    .short_info {
      position: absolute;
      left: 0;
      bottom: 0;
      background: -webkit-linear-gradient(top,transparent,#000);
      background: linear-gradient(to bottom,transparent,#000);
      width: 100%;
      padding: 45px 10px 8px 5px;
      color: $body-color;
      i {
        @include fontSize(25px);
        display: inline-block;
        vertical-align: middle;
        font-weight: normal;
        font-style: normal;
        padding: 0;
        margin: 0;
      }
      .price {
        float: right;
        @include fontSize(28px);
        font-weight: bold;
        display: inline-block;
        span.old_price {
          text-decoration: line-through;
          padding-right: 5px;
        }
        i.icon-left {
          font-size: 0.625rem;
        }
      }
      .price sup {
        @include fontSize(18px);
        position: relative;
        top: -5px;
      }
    }
    small {
      @include rgba(bg, $body-color, 1);
      color: #000;
    }
    a img {
      height: 100%;
      object-fit: cover;
    }
    a.wish_bt {
      &:after {
        content: "\e030";
      }
      &.liked:after {
        content: "\e089";
      }
      &:hover:after {
        content: "\e089";
      }
    }
  }
  .featured_border {
    border: 4px solid orange;
  }
  .wrapper {
    a {
      @include fontSize(16px);
      color: $color-4;
      font-weight: 600;
    }
    .strip_icons {
      color: #000;
      margin-top: 5px;
      margin-bottom: 10px;
      i {
        font-weight: 600;
      }
    }
  }
  
}

.strip_sm {
	background-color: transparent;
  @include box-shadow (0px 0px 30px 0px transparent);
  figure {
		@include border-radius(5px);
  }
  .wrapper {
    padding: 7px 12px;
    div {
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.strip_lg {
  @include border-radius(5px);
  .wrapper  {
    padding: 10px 16px;
    min-height: 206px;
  }
  .score {
    strong {
      color: $color-4;
      background-color: $color-1;
      &:hover {
        background-color: $color-5;
        color: $color-4 !important;
      }
    }
  }
}

/* LISTING > PARTIALS > ROW */

.strip_all_tour_list {
  margin-bottom: 30px;
  background-color: $body-color;
  display: block;
  color: $body-text-color;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.strip_all_tour_list h3 {
  text-transform: uppercase;
}

.strip_all_tour_list .wishlist {
  position: absolute;
  top: 10px;
  right: 25px;
  z-index: 2;
  line-height: 30px;
  @include fontSize(14px);
  text-align: center;
  color: $body-color !important;
}

.strip_all_tour_list a.wish_bt {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
  @include rgba(bg, #000, 0.6);
  padding: 9px 10px;
  display: inline-block;
  color: $body-color;
  line-height: $line-height-none;
  @include border-radius(3px);
  &:after {
    font-family: 'ElegantIcons';
    content: "\e030";
    @include transition-default (all, 0.5s, ease);
  }
  &.liked:after {
    content: "\e089";
    color: $color-5;
  }
  &:hover.liked:after {
    color: $color-5;
  }
  &:hover:after {
    content: "\e089";
    color: $body-color;
  }
}

.img_list {
  overflow: hidden;
  min-height: 220px;
  text-align: center;
  position: relative;
}

.img_list img {
  width: auto;
  height: 220px;
  position: absolute;
  left: -10%;
}

.img_list a img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-backface-visibility: hidden;
}

.img_list a:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.img_list .short_info {
  padding: 5px 5px 5px 5px;
  text-align: left;
  min-height: 38px;
}

.price_list .button {
  display: block;
}

.tour_list_desc {
  padding: 20px 20px 0 0;
  border-right: 1px solid #ededed;
  height: 220px;
  line-height: 17px;
}

.tour_list_desc .tour_list_title {
  @include fontSize(21px);
  font-weight: 600;
}

.tour_list_desc p {
  margin-bottom: 10px;
}

.price_list {
  display: table;
  height: 220px;
  @include fontSize(45px);
  color: #51bce6;
  width: 100%;
  margin-left: -15px;
}

.price_list small {
  @include fontSize(11px);
  color: #999;
  display: block;
  margin-bottom: 10px;
  line-height: 12px;
}

.price_list div {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.price_list p {
  padding: 0;
  margin: 0;
}

.price_list .normal_price_list {
  display: block;
  text-decoration: line-through;
  color: #999;
  @include fontSize(20px);
}

.price_list sup {
  @include fontSize(22px);
  position: relative;
  top: -10px;
}

.price_list a {
  @include fontSize(16px);
  padding: 15px 15px;
}

ul.add_info {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.add_info li {
  display: inline-block;
  margin-right: 5px;
  border: 1px solid #ededed;
  text-align: center;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

ul.add_info li a {
  color: $body-text-color;
  width: 35px;
  height: 35px;
  display: block;
}

ul.add_info li i {
  display: inline-block;
  @include fontSize(30px);
  top: 5px;
  right: 3px;
  position: relative;
}

/* PARTIALS > IMAGE HEADER BOTTOM */
.parallax-content-2 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
  padding: 25px 0 20px 0;
  background: -webkit-linear-gradient(top,transparent,#000);
  background: linear-gradient(to bottom,transparent,#000);
  width: 100%;
}

.parallax-content-2 div h1 {
  @include fontSize(36px);
  text-transform: uppercase;
  font-weight: bold;
  color: $body-color;
  margin: 10px;
}

@media (max-width: 991px) {
  .parallax-content-2 div h1 {
    @include fontSize(25px);
  }
}

/* PARTIALS > SUBHEADER */
.sub_header_in {
	h1 {
		color: $color-4;
	}
}

/* PARTIALS > SECONDARY NAV */
.secondary_nav {
  ul {
    li {
      font-weight: 600;
      a {
        color: $color-4;
      }
      &:last-child {
        display: inline-block;

      }
    }
  }
  .nav-link {
    padding: 0
  }
}

/* LISTING > PARTIALS > LISTING SLIDER PRO */
#Img_carousel .sp-selected-thumbnail {
  border-bottom: 2px solid $color-1;
}

/* LISTING > SHOW > LAYOUT */

#single_tour_feat ul {
  border-bottom: 1px solid $body-text-color;
  li {
    display: table-cell;
    width: 1%;
    i {
      @include fontSize(36px);
      display: block;
      margin-bottom: 10px;
    }
  }
}

.shifts_table .score span {
  top: 0;
}

.listing_show {
  section {
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 10px;
  }
  .section_other {
    ul {
      list-style: none;
      margin: 0 0 30px 0;
      padding: 0;
      li {
        position: relative;
        padding-left: 25px;
        margin-bottom: 10px;
        &:before {
          font-family: 'ElegantIcons';
          content: "\52";
          position: absolute;
          left: 0;
          top: -5px;
          @include fontSize(18px);
          color: $color-3;
        }
      }
    }
  }
  
}

.main_title_3 h2, .main_title_3 h3 {
  font-weight: 700;
}

ul.list_ok {
	list-style: none;
	margin: 0 0 30px 0;
	padding: 0;
	li {
		position: relative;
		padding-left: 25px;
		margin-bottom: 10px;
		&:before {
			font-family: 'ElegantIcons';
			content: "\52";
			position: absolute;
			left: 0;
			top: -5px;
			@include fontSize(18px);
			color: $color-3;
		}
	}
}

/* LISTING > SHOW > CONTACT_FORM */

.box_detail .price p{
  @include fontSize(21px);
  font-weight: 700;
  margin-bottom: 0;
}

ul.share-buttons li a {
  padding: 7px 6px;
}

#confirm {
  background-color: $body-color;
}

.booking input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

.booking .container_check {
  @include fontSize(12px);
}

.box_detail.booking .price {
  padding: 0 25px 0 25px;
}

.box_detail.booking .price h3 {
  margin: -30px -25px 0 -25px;
  background-color: #565a5c;
  padding: 10px 20px 10px 18px;
  color: $body-color;
  border: 1px solid $body-color;
  -webkit-border-top-left-radius: 3px;
  text-align: center;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-topright: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.box_with_avatar {
	.bw-box {
		position: relative;
		margin-bottom: 25px;
		padding-left: 100px;
		min-height: 100px;
	}
	.bw-thumb {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 80px;
		height: 80px;
		background: $body-color;
		@include border-radius(3px);
		overflow: hidden;
		img {
			width: 80px;
			height: auto;
		}
	}
	.bw-content {
		position: relative;
		padding: 0 25px;
		@include border-radius(3px);
	}
}

/* LISTING > SHOW > SHARE_BTN */
ul.share-buttons {
	li {
    margin: 0 2px 5px 0;
		 a {
			&.view-company {
				color: $body-color;
        background: $color-3;
				&:hover {background: $body-color; color: $color-3; border-color: $color-3;}
			}
      &.call-back {
				color: $body-color;
        background: #dd4b39;
				&:hover {background: $body-color; color: #dd4b39; border-color:#dd4b39;}
			}
      &.download-pdf {
				color: $body-color;
        background: $color-2;

				&:hover {background: $body-color; color: $color-2; border-color: $color-2;}
			}
		}
	}
}

/* LISTING > SHOW > CONTACT_BTN */
#contact_btn_group {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  background-color: $body-color;
  cursor: pointer;
  padding: 5px;
}

#contact_btn_group div {
  padding-right: 2px;
  padding-left: 2px;
}

#contact_btn_group .btn_1 {
  @include fontSize(21px);
  margin-bottom: 0;
  padding: 15px 2px;
}

#contactModal .modal-backdrop,
#callBackModal .modal-backdrop,
#favouritesModal .modal-backdrop {
  width: 100%;
  height: 100%;
}

/* LISTING > SHOW > RESERVATION_BTN */
.btn.reservation_btn.disabled {
  color: $body-color;
  background-color: $color-1;
}

/* PARTIALS > IMAGE HEADER CENTER */
.parallax-content-1 {
  display: table;
  width: 100%;
  height: 350px;
  background: rgba(0, 0, 0, 0.3);
}

.parallax-content-1 > div {
  display: table-cell;
  padding: 0 15%;
  vertical-align: middle;
  text-align: center;
  color: $body-color;
  @include fontSize(16px);
}

.parallax-content-1 div h1,
.parallax-content-1 div h3 {
  @include fontSize(30px);
  text-transform: uppercase;
  font-weight: bold;
  color: $body-color;
}

.parallax-content-1 div h1 {
  margin-bottom: 0;
  padding-top: 40px;
}

.parallax-content-1 div h1:before,
.parallax-content-1 div h1:after {
  display: inline-block;
  width: 50px;
  height: 1px;
  content: '';
  vertical-align: middle;
  background: rgba(255, 255, 255, 0.5);
}

.parallax-content-1 div h1:before {
  margin-right: 10px;
}

.parallax-content-1 div h1:after {
  margin-left: 10px;
}

.parallax-content-1 div a i {
  color: $body-color;
  opacity: 0.5;
  @include fontSize(72px);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.parallax-content-1 div a:hover i {
  opacity: 1;
}

/* COMPANY > PARTIALS > PROFILE IMAGE */

.box_topic {
  text-align: center;
  background: $body-color;
  padding: 40px 30px 15px 30px;
  display: block;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  color: $body-text-color;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 3px;
}

/* COMPANY > SHOW > LAYOUT */

.about_us.reviews-container .review-box {
  padding-left: 0px;
}

/* LISTING > INDEX > ROOT */
.list_articles ul li {
  width: 29%;
}

/* LISTING > INDEX > PARTIALS > FILTER */

.filters_listing {
  padding: 0;
	color: $body-color;
	background: $color-1;
  border-bottom: 1px solid $color-1;
	position: relative;
	z-index: 999!important;
	p {
		@include fontSize(16px);
    margin-bottom: 0;
	}
}

.filters {
  background-color: $body-color;
  border-bottom: 1px solid #ededed;
}

.filters_tag {
  padding: 10px 0 5px 0;
  .tags a {
    color: $color-4;
    background-color: $color-1;
    padding: 10px;
    @include fontSize(13px);
    margin: 4px;
    letter-spacing: 0.4px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
  }
  
}

.custom-search-input-2 {
	&.inner {
		.nice-select {
      border-right: 1px solid $border-color-3;
		}
	}
}

.custom-search-input-3 {
	background-color: $color-1;
	@include border-radius(5px);
	margin-top: 10px;
	@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.3));
	@include tablet {
		background: none;
		@include box-shadow (none);
	}
	&.inner {
		margin-top: 0;
		position: relative;
		@include box-shadow (none);
    .filters_text {
      color: $color-4;
      display:flex;
      align-items:center;
    }
    a {
      color: $color-4;
			border: 0;
			padding: 20px;
			border-right: 1px solid $color-4;
			font-weight: 600;
      &:hover {
        color: $color-4;
        background-color: $body-color;
      }
      &.btn_filter {
        &.active{
          color: $color-4;
          background-color: $body-color;
        }
      }
      &#btn_filter_submit {
        border: 1px solid $border-color-3;
        background-color: #32a067;
        &:hover {
          color: #222;
          background-color: $color-5;
        }
      }
		}
	}
}



/* CONTACT */
.box_contacts {
  position: relative;
  @include fontSize(12px);
  background-color: #f8f8f8;
  
}
.box_contacts h3 {
  @include fontSize(21px);
}
.tape {
  position: absolute;
  left: 0;
  top: -25px;
  height: 45px;
  width: 100%;
  background: url(//d3w1mib0z9ze5m.cloudfront.net/template/tape.png) no-repeat center top;
  display: block;
}

/* FOOTER > NEWSLETTER */
#newsletter .container_check {
  @include fontSize(12px);
}

#newsletter #confirm {
  padding: 0;
}

.postcontent {
  padding-bottom: 20px;
}

/* USER > BADGE > LETTER */
.letter .container p {
		margin-bottom: 10px;
}

/* USER > BADGE > BOX */
a.box_topic {
	img {
		margin-bottom: 10px;
	}
}

/* FORM SENT */
.form_sent {
  .box_general {
    &.summary {
      ul {
        li {
          &:last-child {
            font-weight: 500;
            border-bottom: none;
            color: $body-text-color;
          }
        }
      }
    }
  }
}

/* TOOLTIPS */
.tooltip_styled {
  display: inline;
  position: relative;
  z-index: 1;
}

.tooltip-item {
  cursor: pointer;
  display: inline-block;
}

.tooltip-item::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 20px;
  bottom: 100%;
  left: 50%;
  pointer-events: none;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltip_styled:hover .tooltip-item::after {
  pointer-events: auto;
}

.tooltip-content {
  position: absolute;
  z-index: 9999;
  width: 300px;
  left: 50%;
  margin: 0 0 20px -150px;
  bottom: 100%;
  text-align: left;
  line-height: 20px;
  display: block;
  padding: 15px;
  font-size: 12px;
  color: $body-color;
  box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
  background: $heading-color;
  opacity: 0;
  cursor: default;
  pointer-events: none;
}

.tooltip-effect-1 .tooltip-content {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-2 .tooltip-content {
  -webkit-transform-origin: 50% calc(100% + 10px);
  transform-origin: 50% calc(100% + 10px);
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
  transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-3 .tooltip-content {
  -webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-4 .tooltip-content {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scale3d(0.7, 0.3, 1);
  transform: scale3d(0.7, 0.3, 1);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
}

.tooltip_styled:hover .tooltip-content {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
  transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
}

.tooltip_styled.tooltip-effect-2:hover .tooltip-content {
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
  transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
}

.tooltip-content::after {
  content: '';
  top: 100%;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: $heading-color;
  border-width: 10px;
  margin-left: -10px;
}

.tooltip-content .label {
  margin-left: 10px;
}

.tooltip-content i {
  font-size: 20px !important;
  top: 3px !important;
  left: -3px !important;
  position: relative;
}

.tooltip-content h4 {
  display: block;
  margin: 0 0 10px 0;
  line-height: 14px;
  font-size: 14px;
  color: $body-color;
  text-transform: uppercase;
}

.tooltip-content a {
  font-weight: bold;
}

/* Tooltip flip */
.tooltip_flip {
  position: relative;
}

.tooltip_flip::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 20px;
  bottom: 100%;
  pointer-events: none;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltip_flip:hover::after {
  pointer-events: auto;
}

.tooltip-content-flip {
  position: absolute;
  z-index: 9999;
  width: 80px;
  height: 80px;
  left: -40%;
  bottom: 100%;
  text-align: center;
  color: $body-color;
  opacity: 0;
  margin-bottom: 7px;
  cursor: default;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.strip_all_tour_list .tooltip-content-flip,
#single_tour_feat .tooltip-content-flip {
  left: -65%;
}

.tooltip-effect-1 .tooltip-content-flip {
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
}

.tooltip_flip:hover .tooltip-content-flip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.tooltip-content-flip > span {
  float: left;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  height: 100%;
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.tooltip-back {
  background: #e04f67;
  font-size: 12px;
  line-height: 14px;
  padding-top: 25px;
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transform: perspective(1000px) rotate3d(0, 1, 0, 90deg);
  transform: perspective(1000px) rotate3d(0, 1, 0, 90deg);
}

.tooltip_flip:hover .tooltip-content-flip > span {
  -webkit-transform: perspective(1000px) rotate3d(0, 1, 0, 0deg);
  transform: perspective(1000px) rotate3d(0, 1, 0, 0deg);
}

.tooltip-content-flip .tooltip-front::after,
.tooltip-content-flip .tooltip-back::after {
  content: '';
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
}

.tooltip-content-flip .tooltip-front::after {
  right: 0;
  border-top: 10px solid #e64b50;
  border-left: 10px solid transparent;
}

.tooltip-content-flip .tooltip-back::after {
  left: 45%;
  border-top: 10px solid #e64b50;
  border-right: 10px solid transparent;
}

/* FUNKYRADIO */
 .funkyradio div {
   clear: both;
   overflow: hidden;
 }

.funkyradio label {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #D1D3D4;
  font-weight: normal;
}

.funkyradio input[type="radio"]:empty,
.funkyradio input[type="checkbox"]:empty {
  display: none;
}

.funkyradio input[type="radio"]:empty ~ label,
.funkyradio input[type="checkbox"]:empty ~ label {
  position: relative;
  line-height: 2.5em;
  text-indent: 3.25em;
  margin-top: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.funkyradio input[type="radio"]:empty ~ label:before,
.funkyradio input[type="checkbox"]:empty ~ label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: '';
  width: 2.5em;
  background: #D1D3D4;
  border-radius: 3px 0 0 3px;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label {
  color: #888;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label:before,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before {
  content: '\2714';
  text-indent: .9em;
  color: #C2C2C2;
}

.funkyradio input[type="radio"]:checked ~ label,
.funkyradio input[type="checkbox"]:checked ~ label {
  color: $body-text-color;
}

.funkyradio input[type="radio"]:checked ~ label:before,
.funkyradio input[type="checkbox"]:checked ~ label:before {
  content: '\2714';
  text-indent: .9em;
  color: $heading-color;
  background-color: #ccc;
}

.funkyradio input[type="radio"]:focus ~ label:before,
.funkyradio input[type="checkbox"]:focus ~ label:before {
  box-shadow: 0 0 0 3px #999;
}

.funkyradio-default input[type="radio"]:checked ~ label:before,
.funkyradio-default input[type="checkbox"]:checked ~ label:before {
  color: $heading-color;
  background-color: #ccc;
}

.funkyradio-primary input[type="radio"]:checked ~ label:before,
.funkyradio-primary input[type="checkbox"]:checked ~ label:before {
  color: $body-color;
  background-color: #337ab7;
}

.funkyradio-success input[type="radio"]:checked ~ label:before,
.funkyradio-success input[type="checkbox"]:checked ~ label:before {
  color: $body-color;
  background-color: #f7941d;
}

.funkyradio-danger input[type="radio"]:checked ~ label:before,
.funkyradio-danger input[type="checkbox"]:checked ~ label:before {
  color: $body-color;
  background-color: #d9534f;
}

.funkyradio-warning input[type="radio"]:checked ~ label:before,
.funkyradio-warning input[type="checkbox"]:checked ~ label:before {
  color: $body-color;
  background-color: #f0ad4e;
}

.funkyradio-info input[type="radio"]:checked ~ label:before,
.funkyradio-info input[type="checkbox"]:checked ~ label:before {
  color: $body-color;
  background-color: #5bc0de;
}


.funkyradio input[type="radio"][disabled]+label,
.funkyradio input[type="checkbox"][disabled]+label {
  color: rgba(210, 216, 221, 0.9);
  background-color: rgba(255, 255, 255, 0.5);
}

.funkyradio input[type="radio"][disabled]+label:hover,
.funkyradio input[type="checkbox"][disabled]+label:hover {
  color: rgba(210, 216, 221, 0.9);
  cursor:not-allowed
}

.funkyradio input[type="radio"][disabled]+label:before,
.funkyradio input[type="checkbox"][disabled]+label:before {
  background-color: rgba(248, 249, 250, 0.9);
}

.funkyradio input[type="radio"][disabled]:hover:not(:checked) ~ label:before,
.funkyradio input[type="checkbox"][disabled]:hover:not(:checked) ~ label:before {
  content: '';
}

/* TOAST */
.toast {
  z-index: 9999;
}

/* BUTTONS */
a.btn_1, .btn_1, a.btn_2, .btn_2 {
    color: $color-4;
}

#top_menu a.btn_add, #top_menu .btn_add {
    color: $color-4 !important;
}